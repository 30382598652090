import { ethers } from "ethers";
import rariFuseLenseAbi from "@/abi/rariFusePoolLense";
import rariRewardsDistributorAbi from "@/abi/rariRewardsDistributor";
import { batch, contract as batchContract } from "@pooltogether/etherplex";
import { homesteadProvider } from "@/utils/wallet";

const FUSE_POOL_LENSE_ADDRESS = "0x6Dc585Ad66A10214Ef0502492B0CC02F0e836eec";
const TROOPER_GARAGE_ADDRESS = "0xDcc615Ba569e60c7eD31B74686624fC1770bdD9A";
const TROOPER_GARAGE_REWARDS_DISTRUBTOR_ADDRESS =
  "0x1f97885BF402382171B0DFd6F188B0e5d7f54C03";

export default async () => {
  const signer = new ethers.VoidSigner(
    "0x8ba1f109551bD432803012645Ac136ddd64DBA72",
    homesteadProvider
  );

  const lense = new ethers.Contract(
    FUSE_POOL_LENSE_ADDRESS,
    rariFuseLenseAbi,
    signer
  );

  const poolAssets = await lense.getPoolAssetsWithData(TROOPER_GARAGE_ADDRESS);

  const contributorRewards = await batch(
    homesteadProvider,
    ...poolAssets.map((pa) =>
      batchContract(
        pa.underlyingSymbol,
        rariRewardsDistributorAbi,
        TROOPER_GARAGE_REWARDS_DISTRUBTOR_ADDRESS
      ).compSupplySpeeds(pa.cToken)
    )
  );

  return poolAssets.map((pa) => {
    return {
      ...pa,
      supplyRewardPerBlock:
        contributorRewards[pa.underlyingSymbol].compSupplySpeeds[0]
    };
  });
};
