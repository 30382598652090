export const STAKING_POOLS = {
  arbitrum: {
    sushiWethForex: {
      stakingContract: "0x5cdEb8ff5FD3a3361E27e491696515F1D119537a",
      stakingToken: "0x9745e5CC0522827958eE3Fc2C03247276D359186",
      stakingTokenName: "SP-WETH-FOREX"
    },
    curveEursFxEUR: {
      stakingContract: "0x140b808C0b7e0d24fee45155473042A6f6F841Aa",
      stakingToken: "0xb0D2EB3C2cA3c6916FAb8DCbf9d9c165649231AE",
      stakingTokenName: "CRV-fxEUR-EURS"
    },
    curveHandle3: {
      stakingContract: "0x68F03C9DB2611C79AAa21b6dFcdF6baC0cd191f6",
      stakingToken: "0xd0dd5d76cf0fc06dabc48632735566dca241a35e",
      stakingTokenName: "CRV-handle3"
    }
  }
};
