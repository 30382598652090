<template>
  <div>
    <div
      :class="`uk-card-title hfi-card-padding-custom hfi-border-remove ${
        network && network !== Network.homestead ? 'disabled-text' : ''
      }`"
    >
      <h4 class="uk-margin-remove-bottom uk-text-left">
        stake, earn &amp; borrow against your FOREX
      </h4>
    </div>

    <div
      class="uk-card uk-card-small hfi-card-padding-custom hfi-border-remove"
    >
      <div
        uk-grid
        class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@l uk-margin-small-top"
      >
        <div>
          <div uk-grid class="uk-grid-small uk-child-width-1-2">
            <div>
              <div class="uk-card uk-card-small hfi-border-remove uk-flex">
                <div class="">
                  <div :class="disableClass">
                    est. APY:
                    {{ displayApy }}
                  </div>
                  <div :class="disableClass">
                    bal:
                    {{ displayForexBalance }}
                  </div>
                  <div :class="disableClass">
                    your staked:
                    {{ displaycForexBalance }}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="card-buttons uk-card uk-card-small hfi-border-remove uk-flex uk-flex-column uk-flex-center"
              >
                <button
                  id="open-stake-button"
                  class="uk-button uk-button-small uk-button-primary hfi-button uk-width-1-1 uk-margin-small-top hfi-forex-button"
                  type="button"
                  :disabled="!canStake"
                  @click="() => openStakeModal('#troopers-garage-stake')"
                >
                  stake FOREX
                  <img
                    style="margin-top: -4px"
                    :src="`FOREXLogoLightBorder.svg`"
                    :alt="`FOREX logo`"
                    width="24"
                  />
                </button>

                <button
                  id="open-stake-button"
                  class="uk-button uk-button-small uk-button-primary hfi-button uk-width-1-1 uk-margin-small-top hfi-forex-button"
                  type="button"
                  :disabled="!canUnstake"
                  @click="() => openStakeModal('#troopers-garage-unstake')"
                >
                  unstake FOREX
                  <img
                    style="margin-top: -4px"
                    :src="`FOREXLogoLightBorder.svg`"
                    :alt="`FOREX logo`"
                    width="24"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            :class="`uk-width-expand ${disableClass}`"
            :style="`${$vssWidth > 969 ? 'margin-top: -30px;' : ''}`"
          >
            <table
              style="width: 100%; margin-bottom: -2px"
              class="uk-table uk-table-responsive uk-table-xxs uk-table-divider"
            >
              <thead
                class="hfi-transactions-table-header hfi-border-top-remove"
              >
                <tr>
                  <th class="">token</th>
                  <th class="uk-text-right">avail. to borrow</th>
                  <th class="uk-text-right">borrow rate</th>
                  <th class="uk-text-right uk-table-expand">supply rate</th>
                </tr>
              </thead>

              <tbody v-if="fusePoolData">
                <tr v-for="tokenData in tokenTableData" :key="tokenData.symbol">
                  <td data-label="token">{{ tokenData.symbol }}</td>
                  <td data-label="avail. to borrow" class="uk-text-right">
                    ${{
                      parseFloat(
                        tokenData.availableToBorrowInUSD
                      ).toLocaleString(undefined, digits(2))
                    }}
                  </td>
                  <td data-label="borrow rate" class="uk-text-right">
                    {{ tokenData.borrowAPY }}%
                  </td>
                  <td data-label="supply rate" class="uk-text-right">
                    <p class="uk-margin-remove">
                      interest {{ tokenData.supplyAPY }}%
                    </p>
                    <p
                      v-if="!isNaN(tokenData.rewardsAPY)"
                      class="uk-margin-remove"
                    >
                      rewards {{ tokenData.rewardsAPY }}%
                    </p>
                    <p
                      v-if="!isNaN(tokenData.totalAPY)"
                      class="uk-margin-remove"
                    >
                      total {{ tokenData.totalAPY.toFixed(2) }}%
                    </p>
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td
                    class="uk-flex uk-flex-middle"
                    style="width: 100%; padding: 12px 0 12px 15px"
                    colspan="4"
                  >
                    <Loading style="margin-left: -15px" scale=".5" />
                  </td>
                </tr>
              </tbody>
            </table>

            <span
              v-if="disableClass"
              class="uk-button uk-button-primary uk-flex uk-flex-center uk-flex-middle uk-width-expand hfi-button hfi-forex-button cursor-default"
              disabled
            >
              borrow against your FOREX
              <i class="fal fa-external-link-square uk-margin-small-left" />
            </span>
            <a
              v-else
              class="uk-button uk-button-primary uk-flex uk-flex-center uk-flex-middle uk-width-expand hfi-button hfi-forex-button"
              href="https://app.rari.capital/fuse/pool/72"
              target="_blank"
            >
              borrow against your FOREX
              <i class="fal fa-external-link-square uk-margin-small-left" />
            </a>
          </div>
        </div>
      </div>

      <StakingModal
        id="troopers-garage-stake"
        title="stake"
        buttonText="stake"
        tokenSymbol="FOREX"
        :balance="forexBalance"
        :onSubmit="stakeForex"
      />
      <StakingModal
        id="troopers-garage-unstake"
        title="unstake"
        buttonText="unstake"
        tokenSymbol="FOREX"
        :balance="cTokenBalance"
        :onSubmit="unstakeForex"
      />
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import getFusePoolData from "@/contracts/utils/getFusePoolData";
import { getTokenPrice } from "@/utils/coingecko";
import { toDollarsAndCents } from "@/utils/currency";
import Token from "@/types/Token";
import getContractAddresses from "@/contracts/utils/getContractAddresses";
import Network from "@/types/Network";
import Loading from "@/components/Loading";
import LiteLanding from "../LiteLanding.vue";
import NumberInput from "@/components/NumberInput";
import RariCTokenAbi from "@/abi/rariCToken";
import { signer } from "@/utils/wallet";
import { fetchForexBalance } from "@/utils/forex";
import StakingModal from "@/components/pools/StakingModal";
import UIkit from "uikit";
import sendTransaction from "@/contracts/utils/sendConvertTransaction";
import VueScreenSize from "vue-screen-size";
import erc20Abi from "@/abi/erc20";

const FOREX_C_TOKEN_ADDRESS = "0x644375F7145c7F2B520058043b9C7A30Ab16f1C3";

export default {
  name: "FusePool",
  components: {
    LiteLanding,
    Loading,
    NumberInput,
    StakingModal,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data() {
    return {
      fusePoolData: undefined,
      forexPriceUsd: undefined,
      Network,
      cTokenBalance: ethers.constants.Zero,
    };
  },
  async mounted() {
    await this.initialiseState();
  },
  watch: {
    async network() {
      await this.initialiseState();
    },
  },
  computed: {
    network() {
      return store.state.network;
    },
    isHomestead() {
      return this.network === Network.homestead;
    },
    account() {
      return store.state.account;
    },
    forexBalance() {
      return store.getters.forexBalance;
    },
    displayForexBalance() {
      return `${
        this.forexBalance && this.isHomestead
          ? this.getDisplayBalance(this.forexBalance)
          : "0"
      } FOREX`;
    },
    displaycForexBalance() {
      return `${
        this.cTokenBalance && this.isHomestead
          ? this.getDisplayBalance(this.cTokenBalance)
          : "0"
      } FOREX`;
    },
    apy() {
      const tokenData = this.tokenData?.find((td) => td.symbol === Token.FOREX);

      return tokenData?.totalAPY;
    },
    displayApy() {
      return `${this.apy ? this.apy.toFixed(2) : "0.00"}%`;
    },
    canStake() {
      return this.isHomestead && this.forexBalance?.gt(0);
    },
    canUnstake() {
      return this.isHomestead && this.cTokenBalance.gt(0);
    },
    tokenData() {
      return this.fusePoolData?.map((token) => {
        return {
          symbol: token.underlyingSymbol,
          availableToBorrowInUSD: this.getAvailableToBorrowInUSD(token),
          borrowAPY: this.ratePerBlockToYear(token.borrowRatePerBlock),
          supplyAPY: this.ratePerBlockToYear(token.supplyRatePerBlock),
          rewardsAPY: this.getRewardsAPY(token),
          totalAPY: this.getTotalApy(token),
        };
      });
    },
    tokenTableData() {
      return this.tokenData?.filter(
        (token) =>
          token.symbol !== Token.FOREX &&
          token.symbol !== Token.ETH &&
          token.symbol !== Token.FEI
      );
    },
    totalAvailableToBorrowInUSD() {
      return this.borrowableTokens.reduce((sum, token) => {
        return sum + this.getAvailableToBorrowInUSD(token);
      }, 0);
    },
    cTokenContract() {
      return new ethers.Contract(FOREX_C_TOKEN_ADDRESS, RariCTokenAbi, signer);
    },
    forexContract() {
      return new ethers.Contract(this.forexAddress, erc20Abi, signer);
    },
    disableClass() {
      return this.isHomestead ? "" : "disabled-opacity";
    },
    forexAddress() {
      return getContractAddresses(Network.homestead).forex;
    },
  },
  methods: {
    async initialiseState() {
      const fusePromise = getFusePoolData();
      const forexPromise = getTokenPrice(this.forexAddress, Network.homestead);

      this.fetchForexCTokenBalance();

      this.fusePoolData = await fusePromise;
      this.forexPriceUsd = await forexPromise;
    },
    getAvailableToBorrowInUSD(token) {
      const highPrecisionUSD = ethers.utils.formatUnits(
        token.liquidity,
        token.underlyingDecimals
      );

      return toDollarsAndCents(highPrecisionUSD).toFixed(2);
    },
    ratePerBlockToYear(ratePerBlock) {
      return (
        (Math.pow((ratePerBlock / 1e18) * 6500 + 1, 365) - 1) *
        100
      ).toFixed(2);
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
    getRewardsAPY(assetData) {
      const BLOCKS_IN_A_YEAR = 2342570;
      const FOREX_DECIMAL_PLACES = 18;
      const COINGECKO_USD_DECIMAL_PLACES = 6;

      // Currently in the pool we are only allowing users to borrow usd against their FOREX.
      // The below code assumes the user is either depositing FOREX or a usd stable coin.
      // Rari does return 'underlying price' for each asset in ETH. We could fetch the ETH usd price
      // to make this function more generic but I decided to get this working quick and dirty.

      const rewardsOverTheYear = assetData.supplyRewardPerBlock.mul(
        BLOCKS_IN_A_YEAR
      );

      if (assetData.underlyingSymbol === Token.FOREX) {
        const forexAPY = rewardsOverTheYear
          .mul(ethers.constants.WeiPerEther)
          .div(assetData.totalSupply)
          .mul("100");
        return toDollarsAndCents(ethers.utils.formatEther(forexAPY));
      }

      if (!this.forexPriceUsd) {
        return 0;
      }

      const usdValueOfRewardsTheYearBN = rewardsOverTheYear.mul(
        ethers.utils.parseUnits(
          this.forexPriceUsd.toString(),
          COINGECKO_USD_DECIMAL_PLACES
        )
      );

      const usdValueOfRewardsTheYear = toDollarsAndCents(
        ethers.utils.formatUnits(
          usdValueOfRewardsTheYearBN,
          FOREX_DECIMAL_PLACES + COINGECKO_USD_DECIMAL_PLACES
        )
      );

      const totalSupplyValueInUSD = toDollarsAndCents(
        ethers.utils.formatUnits(assetData.totalSupply, 18)
      );

      return ((usdValueOfRewardsTheYear / totalSupplyValueInUSD) * 100).toFixed(
        2
      );
    },
    async stakeForex(amount) {
      const allowance = await this.forexContract.allowance(
        this.account,
        FOREX_C_TOKEN_ADDRESS
      );

      if (allowance.lt(amount)) {
        const approvalTransaction = await this.forexContract.populateTransaction.approve(
          FOREX_C_TOKEN_ADDRESS,
          ethers.constants.MaxUint256
        );

        await sendTransaction(
          approvalTransaction,
          `follow wallet instructions to approve your FOREX to be staked`,
          "transaction processing, please wait...",
          async (_t, explorerMessage) => {
            return `approval successful. ${explorerMessage}`;
          }
        );
      }

      const tx = await this.cTokenContract.populateTransaction.mint(amount);

      await sendTransaction(
        tx,
        `follow wallet instructions to confirm staking your FOREX`,
        "staking deposit processing, please wait...",
        async (_t, explorerMessage) => {
          return `staking of ${this.getDisplayBalance(
            amount
          )} FOREX successful. ${explorerMessage}`;
        }
      );

      this.fetchForexCTokenBalance();
      fetchForexBalance();
    },
    async unstakeForex(amount) {
      const tx = await this.cTokenContract.populateTransaction.redeemUnderlying(
        amount
      );
      await sendTransaction(
        tx,
        `follow wallet instructions to confirm unstaking your FOREX`,
        "unstaking transaction processing, please wait...",
        async (_t, explorerMessage) => {
          return `unstaking of of ${this.getDisplayBalance(
            amount
          )} FOREX successful. ${explorerMessage}`;
        }
      );
      this.fetchForexCTokenBalance();
      fetchForexBalance();
    },
    async fetchForexCTokenBalance() {
      if (this.network !== Network.homestead || !signer) {
        return;
      }

      this.cTokenBalance = await this.cTokenContract.balanceOfUnderlying(
        this.account
      );
    },
    getDisplayBalance(bn) {
      return toDollarsAndCents(ethers.utils.formatEther(bn)).toFixed(2);
    },
    getTotalApy(token) {
      return (
        Number(this.ratePerBlockToYear(token.supplyRatePerBlock)) +
        Number(this.getRewardsAPY(token))
      );
    },
    openStakeModal(id) {
      UIkit.modal(id).show();
    },
    isLoadingForUser(value) {
      return (!value && this.isHomestead) || !this.network;
    },
  },
};
</script>
