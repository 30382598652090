<template>
  <AddLPLiquidity
    :contractAddress="contractAddress"
    :tokenOneAddress="tokenOneAddress"
    :tokenTwoAddress="tokenTwoAddress"
    :tokenThreeAddress="tokenThreeAddress"
    :getMinimumLpTokensToReceive="getMinimumLpTokensToReceive"
    :addLiquidity="addLiquidity"
    :lpTokenName="lpTokenName"
    :tokenApprovalAddress="contractAddress"
  />
</template>

<script>
import AddLPLiquidity from "@/components/pools/AddLPLiquidity";
import { signer } from "@/utils/wallet";
import curveFactoryPoolABI from "@/abi/curveFactoryPool";
import curveZapABI from "@/abi/curveZap";
import { ethers } from "ethers";

export default {
  name: "AddCurveLiquidity",
  components: {
    AddLPLiquidity,
  },
  props: [
    "contractAddress",
    "tokenOneAddress",
    "tokenTwoAddress",
    "tokenThreeAddress",
    "lpTokenName",
  ],
  data() {
    return {};
  },
  computed: {
    poolContract() {
      return new ethers.Contract(
        this.contractAddress,
        curveFactoryPoolABI,
        signer
      );
    },
    zapContract() {
      return new ethers.Contract(
        "0x7544Fe3d184b6B55D6B36c3FCA1157eE0Ba30287",
        curveZapABI,
        signer
      );
    },
  },
  methods: {
    async getMinimumLpTokensToReceive(amounts) {
      const safeAmounts = this.safeAmounts(amounts);

      const result = this.tokenThreeAddress
        ? await this.zapContract.calc_token_amount(
            this.contractAddress,
            safeAmounts,
            true
          )
        : await this.poolContract.calc_token_amount(safeAmounts, true);

      // account for 1% slippage
      return result.div(100).mul(99);
    },
    async addLiquidity(amounts, minimumLpTokensToReceive) {
      const safeAmounts = this.safeAmounts(amounts);

      return this.tokenThreeAddress
        ? // no idea why i have to call it like this
          this.zapContract.populateTransaction[
            "add_liquidity(address,uint256[3],uint256)"
          ](this.contractAddress, safeAmounts, minimumLpTokensToReceive)
        : this.poolContract.populateTransaction.add_liquidity(
            safeAmounts,
            minimumLpTokensToReceive
          );
    },

    safeAmounts(amounts) {
      return amounts.reduce((progress, next) => {
        if (next) {
          return [...progress, next];
        } else {
          return progress;
        }
      }, []);
    },
  },
};
</script>
