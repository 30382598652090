<template>
  <div class="uk-container uk-container-large uk-width-1-1 uk-height-1-1">
    <vue-headful
      description="stake tokens to earn &amp; receive rewards"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | earn`"
    />
    <div>
      <h2 class="uk-h2 uk-text-center uk-margin-remove-bottom">
        {{ $route.name.toLowerCase() }}
      </h2>
    </div>

    <div>
      <ul id="pool-tabs" uk-tab class="uk-margin-remove-bottom uk-flex-center">
        <li :class="`${network === NetworkType.arbitrum ? 'uk-active' : ''}`">
          <a href="" @click="switchTo(NetworkType.arbitrum)">
            {{ NETWORK_NAMES[NetworkType.arbitrum] }}
            <NetworkIcon
              :network="NetworkType.arbitrum"
              class="uk-margin-xsmall-left"
              style="margin-top: -4px"
              height="20"
              width="20"
            />
          </a>
        </li>

        <li :class="`${network === NetworkType.homestead ? 'uk-active' : ''}`">
          <a href="" @click="switchTo(NetworkType.homestead)">
            {{ NETWORK_NAMES[NetworkType.homestead] }}
            <NetworkIcon
              :network="NetworkType.homestead"
              class="uk-margin-xsmall-left"
              style="margin-top: -4px"
              height="20"
              width="20"
            />
          </a>
        </li>

        <li :class="`${network === NetworkType.polygon ? 'uk-active' : ''}`">
          <a href="" @click="switchTo(NetworkType.polygon)">
            {{ NETWORK_NAMES[NetworkType.polygon] }}
            <NetworkIcon
              :network="NetworkType.polygon"
              class="uk-margin-xsmall-left"
              style="margin-top: -4px"
              height="20"
              width="20"
            />
          </a>
        </li>

        <li :class="`${!network ? 'uk-active' : ''}`">
          <a href="" @click="switchTo('all')">all</a>
        </li>
      </ul>
    </div>

    <vue-custom-scrollbar
      v-if="showNetwork !== NetworkType.polygon"
      id="pool-scroll-area"
      :settings="vueCustomScrollbarSettings"
      class="scroll-area uk-overflow-auto"
      :style="{
        height: 'calc(100vh - 260px)',
        margin: '0 -40px',
        padding: '0 40px',
      }"
    >
      <div class="uk-container uk-container-large uk-width-1-1">
        <div
          :class="`${$route.name.toLowerCase()} uk-flex uk-flex-wrap uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m uk-grid-row-collapse uk-grid-match`"
          uk-grid
        >
          <div
            v-if="
              showNetwork === 'all' || showNetwork === NetworkType.homestead
            "
          >
            <StakingBox
              title="troopersGarage"
              logo="/rariLogo.svg"
              :forNetwork="NetworkType.homestead"
              :switchNetworkMessage="`click to switch to ${
                NETWORK_NAMES[NetworkType.homestead]
              } to access troopersGarage staking`"
            >
              <FusePool />
            </StakingBox>
          </div>

          <div
            v-if="showNetwork === 'all' || showNetwork === NetworkType.arbitrum"
          >
            <StakingBox
              title="sushiswap WETH-FOREX"
              titleLink="https://app.sushi.com/swap?inputCurrency=&outputCurrency=0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B"
              headline="deposit in sushiswap, receive LP tokens &amp; stake them to earn FOREX rewards"
              logo="/sushiswapLogo.svg"
              :forNetwork="NetworkType.arbitrum"
              :switchNetworkMessage="`click to switch to ${
                NETWORK_NAMES[NetworkType.arbitrum]
              } to access sushiswap LP pool`"
            >
              <div
                uk-grid
                class="uk-grid-small uk-grid-match uk-width-1-1 uk-child-width-1-2@m uk-flex"
              >
                <div>
                  <AddSushiLiquidity
                    :lpTokenName="
                      stakingPoolAddresses.arbitrum.sushiWethForex
                        .stakingTokenName
                    "
                    :contractAddress="
                      stakingPoolAddresses.arbitrum.sushiWethForex.stakingToken
                    "
                    :tokenOneAddress="
                      contractAddresses.arbitrum.thirdPartyContracts.WETH
                    "
                    :tokenTwoAddress="contractAddresses.arbitrum.forex"
                  />
                </div>
                <div>
                  <LPStakingPool
                    :stakingTokenName="
                      stakingPoolAddresses.arbitrum.sushiWethForex
                        .stakingTokenName
                    "
                    :contractAddress="
                      stakingPoolAddresses.arbitrum.sushiWethForex
                        .stakingContract
                    "
                    :stakingTokenAddress="
                      stakingPoolAddresses.arbitrum.sushiWethForex.stakingToken
                    "
                    :getPoolTVL="getTvlOfArbitrumSushiForexEthPool"
                  />
                </div>
              </div>
            </StakingBox>
          </div>

          <div
            v-if="showNetwork === 'all' || showNetwork === NetworkType.arbitrum"
          >
            <StakingBox
              title="FOREX"
              logo="/FOREXLogoLightBorder.svg"
              headline="stake &amp; lock FOREX for rewards, boosts &amp; governance"
              :forNetwork="NetworkType.arbitrum"
              :switchNetworkMessage="`click to switch to ${
                NETWORK_NAMES[NetworkType.arbitrum]
              } to access FOREX governance pool`"
            >
              <GovernancePool />
            </StakingBox>
          </div>

          <div
            v-if="showNetwork === 'all' || showNetwork === NetworkType.arbitrum"
          >
            <StakingBox
              title="curve fxEUR-EURS"
              titleLink="https://arbitrum.curve.fi/factory/7"
              headline="deposit in EUR stables, receive LP tokens &amp; stake them to earn FOREX rewards"
              headlineLink="https://arbitrum.curve.fi/factory/7/deposit"
              logo="/curveLogo.svg"
              :forNetwork="NetworkType.arbitrum"
              :switchNetworkMessage="`click to switch to ${
                NETWORK_NAMES[NetworkType.arbitrum]
              } to access curve LP pool`"
            >
              <div
                uk-grid
                class="uk-grid-small uk-grid-match uk-width-1-1 uk-child-width-1-2@m uk-flex"
              >
                <div>
                  <AddCurveLiquidity
                    :lpTokenName="
                      stakingPoolAddresses.arbitrum.curveEursFxEUR
                        .stakingTokenName
                    "
                    :contractAddress="
                      stakingPoolAddresses.arbitrum.curveEursFxEUR.stakingToken
                    "
                    :tokenOneAddress="contractAddresses.arbitrum.fxTokens.fxEUR"
                    tokenTwoAddress="0xd22a58f79e9481d1a88e00c343885a588b34b68b"
                  />
                </div>

                <div>
                  <LPStakingPool
                    :stakingTokenName="
                      stakingPoolAddresses.arbitrum.curveEursFxEUR
                        .stakingTokenName
                    "
                    :contractAddress="
                      stakingPoolAddresses.arbitrum.curveEursFxEUR
                        .stakingContract
                    "
                    :stakingTokenAddress="
                      stakingPoolAddresses.arbitrum.curveEursFxEUR.stakingToken
                    "
                    :getPoolTVL="getTvlOfArbitrumCurveEurFxEurPool"
                  />
                </div>
              </div>
            </StakingBox>
          </div>

          <div
            v-if="showNetwork === 'all' || showNetwork === NetworkType.arbitrum"
          >
            <StakingBox
              title="curve fxUSD-USDC-USDT"
              titleLink="https://arbitrum.curve.fi/factory/12"
              headline="deposit in USD stables, receive LP tokens &amp; stake them to earn FOREX rewards"
              headlineLink="https://arbitrum.curve.fi/factory/12/deposit"
              logo="/curveLogo.svg"
              :forNetwork="NetworkType.arbitrum"
              :switchNetworkMessage="`click to switch to ${
                NETWORK_NAMES[NetworkType.arbitrum]
              } to access curve LP pool`"
            >
              <div
                uk-grid
                class="uk-grid-small uk-grid-match uk-width-1-1 uk-child-width-1-2@m uk-flex"
              >
                <div>
                  <AddCurveLiquidity
                    :lpTokenName="
                      stakingPoolAddresses.arbitrum.curveHandle3
                        .stakingTokenName
                    "
                    :contractAddress="
                      stakingPoolAddresses.arbitrum.curveHandle3.stakingToken
                    "
                    :tokenOneAddress="contractAddresses.arbitrum.fxTokens.fxUSD"
                    tokenTwoAddress="0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"
                    tokenThreeAddress="0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9"
                  />
                </div>

                <div>
                  <LPStakingPool
                    :stakingTokenName="
                      stakingPoolAddresses.arbitrum.curveHandle3
                        .stakingTokenName
                    "
                    :contractAddress="
                      stakingPoolAddresses.arbitrum.curveHandle3.stakingContract
                    "
                    :stakingTokenAddress="
                      stakingPoolAddresses.arbitrum.curveHandle3.stakingToken
                    "
                    :getPoolTVL="getTvlOfArbitrumCurveEurFxEurPool"
                  />
                </div>
              </div>
            </StakingBox>
          </div>

          <div
            v-if="showNetwork === 'all' || showNetwork === NetworkType.arbitrum"
          >
            <StakingBox
              title="fxKeeper pools"
              logo="/handle.fiLogoCutInverted.svg"
              :forNetwork="NetworkType.arbitrum"
              :switchNetworkMessage="`click to switch to ${
                NETWORK_NAMES[NetworkType.arbitrum]
              } to access fxKeeper pools`"
            >
              <KeeperPools />
            </StakingBox>
          </div>
        </div>
      </div>
    </vue-custom-scrollbar>

    <div
      v-else
      class="uk-height-1-1 uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-margin-large-top"
    >
      <img
        src="handle.fiDancingGorilla.gif"
        style="margin: -12px 2px -12px 0"
        class="uk-margin-small-right"
        alt="handle.fi dancing gorilla"
        width="40"
      />

      <span class="uk-h4 uk-margin-remove-vertical uk-margin-small-left">
        troopers coming soon to polygon...
      </span>
    </div>

    <div
      id="convert-modal"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <ConvertComponent :modal="true" />
      </div>
    </div>

    <div
      id="mint-modal"
      v-if="account && sdk && false"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <BorrowComponent />
      </div>
    </div>

    <div
      id="forex-liquidity-stake-modal"
      v-if="account && sdk && false"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <ForexLiquidityStake />
      </div>
    </div>

    <div
      id="forex-liquidity-unstake-modal"
      v-if="account && sdk && false"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <ForexLiquidityUnstake />
      </div>
    </div>

    <div
      id="handle-governance-stake-modal"
      v-if="account && sdk && false"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <HandleGovernanceStake />
      </div>
    </div>

    <div
      id="handle-governance-unstake-modal"
      v-if="account && sdk && false"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <HandleGovernanceUnstake />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import ConvertComponent from "@/components/ConvertComponent";
import BorrowComponent from "@/components/BorrowComponent";
import UIkit from "uikit";
import SelectFxToken from "@/components/SelectFxToken";
import FxKeeperUnstake from "@/components/FxKeeperUnstake";
import HandleGovernanceStake from "@/components/HandleGovernanceStake";
import HandleGovernanceUnstake from "@/components/HandleGovernanceUnstake";
import ForexLiquidityStake from "@/components/ForexLiquidityStake";
import ForexLiquidityUnstake from "@/components/ForexLiquidityUnstake";
import FusePool from "@/components/pools/FusePool";
import KeeperPools from "@/components/pools/KeeperPools";
import LPStakingPool from "@/components/pools/LPStakingPool";
import GovernancePool from "@/components/pools/GovernancePool";
import { ethers } from "ethers";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { getDecimalsAmount } from "@/utils/utils";
import { STAKING_POOLS } from "@/utils/constants/stakingPool";
import NetworkType from "@/types/Network";
import {
  getTvlOfArbitrumSushiForexEthPool,
  getTvlOfArbitrumCurveEurFxEurPool,
} from "@/utils/getStakingPoolTVL";
import AddCurveLiquidity from "@/components/pools/AddCurveLiquidity";
import AddSushiLiquidity from "@/components/pools/AddSushiLiquidity";
import contractAddresses from "@/contracts.config.json";
import StakingBox from "@/components/pools/StakingBox";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import NetworkIcon from "@/components/NetworkIcon";
import { switchNetwork } from "@/utils/wallet";

export default {
  name: "Earn",
  components: {
    vueCustomScrollbar,
    ConvertComponent,
    BorrowComponent,
    SelectFxToken,
    FxKeeperUnstake,
    HandleGovernanceStake,
    HandleGovernanceUnstake,
    ForexLiquidityStake,
    ForexLiquidityUnstake,
    FusePool,
    KeeperPools,
    LPStakingPool,
    GovernancePool,
    AddCurveLiquidity,
    StakingBox,
    NetworkIcon,
    AddSushiLiquidity,
  },
  data() {
    return {
      signer: null,
      stakingPoolAddresses: STAKING_POOLS,
      getTvlOfArbitrumSushiForexEthPool,
      getTvlOfArbitrumCurveEurFxEurPool,
      contractAddresses,
      currency: "",
      vueCustomScrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
        minScrollbarLength: 15,
        wheelSpeed: 0.125,
      },
      NetworkType,

      formatEther: (value, digits = 2, tokenDecimals = 18) => {
        if (value == null) return "n/a";
        value = getDecimalsAmount(value, tokenDecimals, 18);
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
      rewards: false,
      processingFxKeeperPoolClaim: false,
      NETWORK_NAMES,
      showNetwork: "all",
    };
  },

  computed: {
    network() {
      return this.account ? store.state.network : null;
    },

    account() {
      return store.state.account;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },

    isLite() {
      return store.state.isLite;
    },
  },
  watch: {
    network(value) {
      this.showNetwork = this.account ? value : "all";
      this.switchTo(this.showNetwork);
    },
    account() {
      this.showNetwork = this.account ? this.network : "all";
      this.switchTo(this.showNetwork);
    },
  },

  mounted() {
    this.showNetwork = this.account ? this.network : "all";
    this.switchTo(this.showNetwork);
  },

  methods: {
    async convert(token) {
      await store.commit("setToken", { token, type: "to" });
      UIkit.modal("#convert-modal").show();
    },
    async mint() {
      UIkit.modal("#mint-modal").show();
    },
    openHandleGovernanceStakeModal() {
      UIkit.modal("#handle-governance-stake-modal").show();
    },
    openHandleGovernanceUnstakeModal() {
      UIkit.modal("#handle-governance-unstake-modal").show();
    },
    openForexLiquidityStakeModal() {
      UIkit.modal("#forex-liquidity-stake-modal").show();
    },
    openForexLiquidityUnstakeModal() {
      UIkit.modal("#forex-liquidity-unstake-modal").show();
    },
    switchTo(network) {
      this.showNetwork = network;
      if (network === "all" || network === this.network) return;
      if (network !== this.network) switchNetwork(network);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/handle.fi" as handle;

/*#pool-tabs:after {
  content: "";
  bottom: 0;
  width: 100vw;
  height: 1px;
  background-color: #{handle.$green + "44"};
  margin: 0 -60px 0 -40px;
}*/
</style>
