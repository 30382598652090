<template>
  <div
    :id="id"
    class="uk-flex-top uk-margin-remove-top"
    uk-modal="bgClose: false; container: false;"
  >
    <div
      class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small"
    >
      <h4 class="uk-margin-small-bottom">{{ title }} {{ tokenSymbol }}</h4>
      <a @click.prevent="onClose" class="uk-modal-close-default">
        <i class="fal fa-times"></i>
      </a>
      <div class="uk-margin">
        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between"
          for="amount"
        >
          <span>amount</span>
          <span>
            {{ displayBalanceText }}:
            {{ displayBalance }}
            {{ tokenSymbol }}
          </span>
        </label>

        <div class="uk-position-relative uk-flex">
          <NumberInput
            class="uk-width-expand"
            id="amount"
            type="number"
            :placeholder="`amount to ${title}`"
            :value="!disabled ? amount : amount.mul(0)"
            :disabled="disabled"
            @change="onChangeAmount"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            :disabled="disabled"
            @click="setMax"
          >
            max
          </button>
        </div>
        <div class="uk-position-relative uk-flex uk-margin-small-top">
          <slot></slot>
        </div>
        <button
          id="action-button"
          class="uk-button uk-button-primary hfi-button uk-width-expand uk-margin-small-top"
          type="button"
          :disabled="!canSubmit"
          @click="submit"
        >
          <vue-loaders-ball-pulse
            v-if="loading"
            color="currentColor"
            scale="0.5"
            style="margin-bottom: -6px"
          />
          <span v-else> {{ buttonText }} </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import Network from "@/types/Network";
import Loading from "@/components/Loading";
import LiteLanding from "../LiteLanding.vue";
import NumberInput from "@/components/NumberInput";
import { toDollarsAndCents } from "@/utils/currency";
import UIkit from "uikit";

export default {
  name: "StakingModal",
  components: { LiteLanding, Loading, NumberInput },
  props: [
    "id",
    "title",
    "balance",
    "tokenSymbol",
    "onSubmit",
    "buttonText",
    "disabled",
    "forceAllowSubmit",
  ],
  data() {
    return {
      Network,
      amount: ethers.constants.Zero,
      loading: false,
    };
  },

  computed: {
    network() {
      return store.state.network;
    },
    displayBalanceText() {
      if (
        this.title === "stake" ||
        this.title === "deposit" ||
        this.title === "lock"
      )
        return "bal";
      if (this.title === "unstake") return "staked";
      if (this.title === "withdraw") return "deposited";
    },
    displayBalance() {
      if (!this.balance) {
        return "-";
      }

      return toDollarsAndCents(ethers.utils.formatEther(this.balance)).toFixed(
        2
      );
    },
    canSubmit() {
      return (
        this.forceAllowSubmit ||
        (this.amount.gt(0) && this.balance && this.amount.lte(this.balance))
      );
    },
  },
  methods: {
    onChangeAmount(amount) {
      this.amount = amount;
      this.$emit("change", amount);
    },
    setMax() {
      this.amount = this.balance;
    },
    async submit() {
      try {
        this.loading = true;
        await this.onSubmit(this.amount);
        this.amount = ethers.constants.Zero;
        this.onClose();
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    onClose() {
      UIkit.modal(`#${this.id}`).hide();
    },
  },
};
</script>
