<template>
  <div class="uk-flex uk-flex-center" v-bind="$attrs">
    <div
      :class="`uk-card uk-card-body uk-flex uk-margin-small-medium-top hfi-stake-card-padding uk-width-expand ${
        wrongNetwork ? 'disabled-border' : ''
      }`"
    >
      <div
        style="top: -20px; left: -2px; z-index: 1"
        :class="`uk-h4 uk-card-title uk-flex hfi-border hfi-background hfi-stake-card-title-padding uk-position-absolute uk-flex-middle ${
          wrongNetwork ? 'disabled-text disabled-border' : ''
        }`"
      >
        <img
          :class="`uk-margin-small-right ${
            wrongNetwork ? 'disabled-opacity' : ''
          }`"
          :src="logo"
          alt="pool logo"
          width="24"
        />
        <h4 class="uk-h4 uk-margin-remove">
          <span v-if="titleLink && !wrongNetwork">
            <a class="hfi-link" :href="titleLink" target="_blank">
              {{ title }}
              <i class="fal fa-external-link-square uk-margin-small-right"></i>
            </a>
          </span>

          <span v-else>
            {{ title }}
          </span>

          <NetworkIcon
            :network="forNetwork"
            :class="`uk-margin-xsmall-left ${
              wrongNetwork ? 'disabled-opacity' : ''
            }`"
            style="margin-top: -4px"
            height="20"
            width="20"
          />
        </h4>
      </div>

      <div
        class="uk-card uk-card-small hfi-border-remove uk-margin-small-top uk-width-1-1 uk-flex-between uk-flex uk-flex-column"
      >
        <div
          v-if="headline"
          :class="`uk-card-title hfi-card-padding-custom hfi-border-remove ${
            wrongNetwork ? 'disabled-text' : ''
          }`"
        >
          <h4 class="uk-margin-small-bottom uk-text-left">
            <span v-if="headlineLink && !wrongNetwork">
              <a
                class="hfi-link"
                :href="headlineLink"
                target="_blank"
                uk-tooltip="title: follow link to add liquidity; pos: bottom;"
              >
                {{ headline }}
              </a>
            </span>

            <span v-else>
              {{ headline }}
            </span>
          </h4>
        </div>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { switchNetwork } from "@/utils/wallet";
import NetworkIcon from "@/components/NetworkIcon";

export default {
  name: "StakingBox",
  props: [
    "title",
    "titleLink",
    "headline",
    "headlineLink",
    "logo",
    "forNetwork",
    "switchNetworkMessage",
    "disabled",
  ],
  components: {
    NetworkIcon,
  },
  computed: {
    wrongNetwork() {
      return this.forNetwork !== store.state.network;
    },
  },
  methods: {
    switchNetwork() {
      switchNetwork(this.forNetwork);
    },
  },
};
</script>
