<template>
  <vue-loaders-ball-pulse
    color="currentColor"
    v-bind="$attrs"
    style="margin-left: -15px; margin-bottom: -5px"
  />
</template>

<script>
export default {
  name: "Loading",
};
</script>
