<template>
  <div class="uk-flex uk-flex-center">
    <div
      class="uk-width-expand hfi-card-padding-custom uk-flex uk-flex-center uk-flex-middle"
      v-if="loading"
    >
      <Loading scale=".5" />
    </div>
    <div
      :class="`uk-width-expand hfi-card-padding-custom ${
        wrongNetwork ? 'disabled-text' : ''
      }`"
      v-else
    >
      <div>
        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between"
          for="amount"
        >
          <span>{{ tokenOneSymbol }}</span>
          <span>bal: {{ displayTokenOne }}</span>
        </label>

        <div class="uk-position-relative uk-flex">
          <NumberInput
            class="uk-width-expand"
            id="amount"
            type="number"
            placeholder="amount of liquidity to add"
            :value="tokenOneAmount"
            :decimals="tokenOneDecimals"
            @change="onChangeTokenOneAmount"
            :disabled="wrongNetwork"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            @click="onSetMaxTokenOne"
            :disabled="wrongNetwork"
          >
            max
          </button>
        </div>
      </div>

      <div class="uk-margin-xsmall-top">
        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between"
          for="amount"
        >
          <span>{{ tokenTwoSymbol }}</span>
          <span>bal: {{ displayTokenTwo }}</span>
        </label>

        <div class="uk-position-relative uk-flex">
          <NumberInput
            class="uk-width-expand"
            id="amount"
            type="number"
            placeholder="amount of liquidity to add"
            :value="tokenTwoAmount"
            :decimals="tokenTwoDecimals"
            @change="onChangeTokenTwoAmount"
            :disabled="wrongNetwork"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            @click="onSetMaxTokenTwo"
            :disabled="wrongNetwork"
          >
            max
          </button>
        </div>
      </div>

      <div class="uk-margin-xsmall-top" v-if="tokenThreeAddress">
        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between"
          for="amount"
        >
          <span>{{ tokenThreeSymbol }}</span>
          <span>bal: {{ displayTokenThree }}</span>
        </label>

        <div class="uk-position-relative uk-flex">
          <NumberInput
            class="uk-width-expand"
            id="amount"
            type="number"
            placeholder="amount of liquidity to add"
            :value="tokenThreeAmount"
            :decimals="tokenThreeDecimals"
            @change="onChangeTokenThreeAmount"
            :disabled="wrongNetwork"
          />
          <button
            class="uk-button hfi-button hfi-input-button"
            @click="onSetMaxTokenThree"
            :disabled="wrongNetwork"
          >
            max
          </button>
        </div>
      </div>

      <div class="uk-margin-xsmall-top">
        <span
          :class="`${getMinimumLpTokensToReceive ? '' : 'hfi-showbuthide'}`"
        >
          minimum LP tokens to receive: {{ displayMinimumTokensToReceive }}
        </span>
      </div>
      <button
        id="add-liqudity-button"
        class="uk-button uk-button-primary hfi-button hfi-forex-button uk-width-expand"
        type="button"
        :disabled="!canAddLiquidity"
        @click.prevent="addLiquidityInternal"
      >
        <Loading v-if="pendingTransaction" scale=".5" />
        <span v-else>add liquidity</span>
      </button>
    </div>
  </div>
</template>

<script>
import { signer, arbitrumProvider } from "@/utils/wallet";
import { ethers } from "ethers";
import { store } from "@/store";
import Network from "@/types/Network";
import curveFactoryPoolABI from "@/abi/curveFactoryPool";
import erc20Abi from "@/abi/erc20";
import { toDollarsAndCents } from "@/utils/currency";
import NumberInput from "@/components/NumberInput";
import Loading from "@/components/Loading";
import sendTransaction from "@/contracts/utils/sendConvertTransaction";

export default {
  name: "AddLPLiquidity",
  components: {
    Loading,
    NumberInput,
  },
  props: [
    "contractAddress",
    "tokenOneAddress",
    "tokenTwoAddress",
    "tokenThreeAddress",
    "calculateOtherTokenAmount",
    "getMinimumLpTokensToReceive",
    "addLiquidity",
    "lpTokenName",
    "tokenApprovalAddress",
  ],
  data() {
    return {
      poolContract: undefined,
      tokenOneData: undefined,
      tokenOneAmount: ethers.BigNumber.from("0"),
      tokenTwoData: undefined,
      tokenTwoAmount: ethers.BigNumber.from("0"),
      tokenThreeData: undefined,
      tokenThreeAmount: ethers.BigNumber.from("0"),
      minimumLpTokensToReceive: undefined,
      pendingTransaction: false,
    };
  },
  mounted() {
    this.initialise();
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    wrongNetwork() {
      return this.network !== Network.arbitrum;
    },
    canInteract() {
      return !this.wrongNetwork && this.account;
    },
    loading() {
      return (
        !this.tokenOneData ||
        !this.tokenTwoData ||
        (this.tokenThreeAddress && !this.tokenThreeData)
      );
    },
    signer() {
      return this.canInteract
        ? signer
        : new ethers.VoidSigner(
            "0x8ba1f109551bD432803012645Ac136ddd64DBA72", // random address
            arbitrumProvider
          );
    },
    tokenOneContract() {
      return new ethers.Contract(this.tokenOneAddress, erc20Abi, this.signer);
    },
    tokenTwoContract() {
      return new ethers.Contract(this.tokenTwoAddress, erc20Abi, this.signer);
    },
    tokenThreeContract() {
      return this.tokenThreeAddress
        ? new ethers.Contract(this.tokenThreeAddress, erc20Abi, this.signer)
        : undefined;
    },
    tokenOneBalance() {
      return store.state.balances[this.tokenOneData.symbol];
    },
    tokenTwoBalance() {
      return store.state.balances[this.tokenTwoData.symbol];
    },
    tokenThreeBalance() {
      return store.state.balances[this.tokenThreeData.symbol];
    },
    tokenOneSymbol() {
      return this.tokenOneData?.symbol;
    },
    tokenTwoSymbol() {
      return this.tokenTwoData?.symbol;
    },
    tokenThreeSymbol() {
      return this.tokenThreeData?.symbol;
    },
    tokenOneDecimals() {
      return this.tokenOneData?.decimals;
    },
    tokenTwoDecimals() {
      return this.tokenTwoData?.decimals;
    },
    tokenThreeDecimals() {
      return this.tokenThreeData?.decimals;
    },
    displayMinimumTokensToReceive() {
      return this.displayBalanceWithDefault(
        this.minimumLpTokensToReceive,
        18,
        ""
      );
    },
    displayTokenOne() {
      return this.displayBalanceWithDefault(
        this.tokenOneBalance,
        this.tokenOneData.decimals,
        this.tokenOneData.symbol
      );
    },
    displayTokenTwo() {
      return this.displayBalanceWithDefault(
        this.tokenTwoBalance,
        this.tokenTwoData.decimals,
        this.tokenTwoData.symbol
      );
    },
    displayTokenThree() {
      return this.displayBalanceWithDefault(
        this.tokenThreeBalance,
        this.tokenThreeData.decimals,
        this.tokenThreeData.symbol
      );
    },
    canAddLiquidity() {
      return (
        this.canInteract &&
        !this.pendingTransaction &&
        (!!this.tokenThreeAddress ||
          !this.getMinimumLpTokensToReceive ||
          (this.getMinimumLpTokensToReceive &&
            this.minimumLpTokensToReceive)) &&
        (this.tokenOneAmount.gt(0) || this.tokenTwoAmount.gt(0)) &&
        this.tokenOneAmount.gte(0) &&
        this.tokenOneBalance &&
        this.tokenOneBalance.gte(this.tokenOneAmount) &&
        this.tokenTwoAmount.gte(0) &&
        this.tokenTwoBalance &&
        this.tokenTwoBalance.gte(this.tokenTwoAmount) &&
        (!this.tokenThreeAddress ||
          (this.tokenThreeBalance &&
            this.tokenThreeBalance.gte(this.tokenThreeAmount)))
      );
    },
  },
  watch: {
    account() {
      this.initialise();
      this.updateBalance("One");
      this.updateBalance("Two");
      this.updateBalance("Three");
    },
    network() {
      this.initialise();
    },
    tokenOneData() {
      this.updateBalance("One");
    },
    tokenTwoData() {
      this.updateBalance("Two");
    },
    tokenThreeData() {
      this.updateBalance("Three");
    },
  },
  methods: {
    async initialise() {
      this.poolContract = new ethers.Contract(
        this.contractAddress,
        curveFactoryPoolABI,
        this.signer
      );

      this.getTokenData(this.tokenOneAddress).then((data) => {
        this.tokenOneData = data;
      });
      this.getTokenData(this.tokenTwoAddress).then((data) => {
        this.tokenTwoData = data;
      });
      if (this.tokenThreeAddress) {
        this.getTokenData(this.tokenThreeAddress).then((data) => {
          this.tokenThreeData = data;
        });
      }
    },
    async updateUsersLPTokenBalance() {
      store.dispatch("updateBalances", [
        {
          symbol: this.lpTokenName,
          address: this.contractAddress,
        },
      ]);
    },
    async getTokenData(tokenAddress) {
      const token = new ethers.Contract(tokenAddress, erc20Abi, this.signer);

      const tokenSymbolPromise = token.symbol();
      const tokenDeimalsPromise = token.decimals();

      return {
        symbol: await tokenSymbolPromise,
        decimals: await tokenDeimalsPromise,
      };
    },
    displayBalanceWithDefault(balance, decimals, symbol) {
      return balance
        ? this.displayBalance(balance, decimals, symbol)
        : `0 ${symbol}`;
    },
    displayBalance(balance, decimals, symbol) {
      return `${toDollarsAndCents(
        ethers.utils.formatUnits(balance, decimals)
      )} ${symbol}`;
    },
    updateBalance(property) {
      if (!this.canInteract) return;
      if (!this[`token${property}Data`]) return;

      store.dispatch("updateBalances", [
        {
          symbol: this[`token${property}Data`].symbol,
          address: this[`token${property}Address`],
        },
      ]);
    },
    async setOtherTokenAmount(
      token,
      amount,
      tokenOneDecimals,
      tokenTwoDecimals
    ) {
      if (!this.calculateOtherTokenAmount) {
        return;
      }

      const result = await this.calculateOtherTokenAmount(
        token,
        amount,
        tokenOneDecimals,
        tokenTwoDecimals
      );

      if (token === "One") {
        this.tokenTwoAmount = result;
      } else {
        this.tokenOneAmount = result;
      }
    },
    async setMinimumLpTokensToReceive() {
      if (!this.getMinimumLpTokensToReceive) {
        return;
      }

      this.minimumLpTokensToReceive = await this.getMinimumLpTokensToReceive([
        this.tokenOneAmount,
        this.tokenTwoAmount,
        this.tokenThreeAddress ? this.tokenThreeAmount : undefined,
      ]);
    },
    async addLiquidityInternal() {
      try {
        this.pendingTransaction = true;

        const [
          tokenOneAllowance,
          tokenTwoAllowance,
          tokenThreeAllowance,
        ] = await Promise.all([
          this.tokenOneContract.allowance(
            this.account,
            this.tokenApprovalAddress
          ),
          this.tokenTwoContract.allowance(
            this.account,
            this.tokenApprovalAddress
          ),
          this.tokenThreeContract &&
            this.tokenThreeContract.allowance(
              this.account,
              this.tokenApprovalAddress
            ),
        ]);

        if (tokenOneAllowance.lt(this.tokenOneAmount)) {
          const approvalTransaction = await this.tokenOneContract.populateTransaction.approve(
            this.tokenApprovalAddress,
            ethers.constants.MaxUint256
          );

          await sendTransaction(
            approvalTransaction,
            `follow wallet instructions to approve ${this.tokenOneData.symbol}`,
            "transaction processing, please wait...",
            async (_t, explorerMessage) => {
              return `approval successful. ${explorerMessage}`;
            }
          );
        }

        if (tokenTwoAllowance.lt(this.tokenTwoAmount)) {
          const approvalTransaction = await this.tokenTwoContract.populateTransaction.approve(
            this.tokenApprovalAddress,
            ethers.constants.MaxUint256
          );

          await sendTransaction(
            approvalTransaction,
            `follow wallet instructions to approve ${this.tokenTwoData.symbol}`,
            "transaction processing, please wait...",
            async (_t, explorerMessage) => {
              return `approval successful. ${explorerMessage}`;
            }
          );
        }

        if (
          tokenThreeAllowance &&
          tokenThreeAllowance.lt(this.tokenThreeAmount)
        ) {
          const approvalTransaction = await this.tokenThreeContract.populateTransaction.approve(
            this.tokenApprovalAddress,
            ethers.constants.MaxUint256
          );

          await sendTransaction(
            approvalTransaction,
            `follow wallet instructions to approve ${this.tokenThreeData.symbol}`,
            "transaction processing, please wait...",
            async (_t, explorerMessage) => {
              return `approval successful. ${explorerMessage}`;
            }
          );
        }

        const addLiquidityTransaction = await this.addLiquidity(
          [
            this.tokenOneAmount || ethers.BigNumber.from(0),
            this.tokenTwoAmount || ethers.BigNumber.from(0),
            this.tokenThreeAddress
              ? this.tokenThreeAmount || ethers.BigNumber.from(0)
              : undefined,
          ],
          this.minimumLpTokensToReceive
        );

        await sendTransaction(
          addLiquidityTransaction,
          `follow wallet instructions to add liquidity`,
          "transaction processing, please wait...",
          async (_t, explorerMessage) => {
            return `liquidity successfully added. ${explorerMessage}`;
          }
        );

        this.initialise();
        this.updateUsersLPTokenBalance();
        this.updateBalance("One");
        this.updateBalance("Two");
        this.updateBalance("Three");
        this.tokenOneAmount = ethers.BigNumber.from("0");
        this.tokenTwoAmount = ethers.BigNumber.from("0");
        this.tokenThreeAmount = ethers.BigNumber.from("0");
        this.minimumLpTokensToReceive = undefined;
      } catch (error) {
        console.error(error);
      }
      this.pendingTransaction = false;
    },
    async onSetMaxTokenOne() {
      this.tokenOneAmount = this.tokenOneBalance;
    },
    async onSetMaxTokenTwo() {
      this.tokenTwoAmount = this.tokenTwoBalance;
    },
    async onSetMaxTokenThree() {
      this.tokenThreeAmount = this.tokenThreeBalance;
    },
    onChangeTokenAmount(token, amount) {
      this[`token${token}Amount`] = amount;
      this.setMinimumLpTokensToReceive();

      if (!this.tokenThreeAddress) {
        this.setOtherTokenAmount(
          token,
          amount,
          this.tokenTwoData.decimals,
          this.tokenOneData.decimals
        );
      }
    },
    onChangeTokenOneAmount(amount) {
      this.onChangeTokenAmount("One", amount);
    },
    onChangeTokenTwoAmount(amount) {
      this.onChangeTokenAmount("Two", amount);
    },
    onChangeTokenThreeAmount(amount) {
      this.onChangeTokenAmount("Three", amount);
    },
  },
};
</script>
