<template>
  <div class="uk-flex uk-flex-column uk-height-1-1 uk-flex-between">
    <div
      :class="`uk-card-title hfi-card-padding-custom hfi-border-remove ${
        wrongNetwork ? 'disabled-opacity' : ''
      }`"
    >
      <h4 class="uk-margin-remove-bottom uk-text-left">
        deposit fxTokens to earn liquidation profits &amp; FOREX rewards
      </h4>
    </div>
    <div class="uk-flex">
      <div
        class="uk-card uk-card-small hfi-card-padding-custom hfi-border-remove uk-flex uk-flex-column uk-flex-between uk-width-1-1 uk-width-1-2@s"
      >
        <div
          :class="`uk-h4 uk-margin-top uk-margin-remove-bottom ${
            wrongNetwork ? 'disabled-opacity' : ''
          }`"
        >
          <img
            style="margin-top: -4px"
            class="hfi-forex-logo"
            :src="`${token}Logo.png`"
            alt="handle.fi FOREX logo"
            width="28"
          />
          {{ token }}
        </div>
        <div class="uk-margin-small-bottom uk-margin-small-top">
          <div>
            <span
              :class="`hfi-text-highlight ${
                wrongNetwork ? 'disabled-opacity' : ''
              }`"
              >est. APY: {{ apy || "n/a" }}</span
            >
          </div>
          <div :class="`${wrongNetwork ? 'disabled-opacity' : ''}`">
            total deposited: {{ displayTotalStaked }}
          </div>
        </div>

        <div :class="`${wrongNetwork ? 'disabled-opacity' : ''}`">
          <div class="uk-h5 uk-margin-remove uk-flex">
            bal:
            {{ displayBalance }}
          </div>
          <div class="uk-h5 uk-margin-remove uk-flex">
            your deposited:
            {{ displayStaked }}
          </div>
        </div>
      </div>

      <div
        class="card-buttons uk-card uk-card-small hfi-border-remove uk-margin-small-top uk-flex uk-flex-column uk-flex-start uk-flex-middle uk-width-1-1 uk-width-1-2@m"
      >
        <SelectFxToken
          id="keeperMintFxToken"
          :class="`uk-margin-xsmall-top uk-width-1-1 uk-margin-small-bottom keeperMintFxToken-boundary ${
            wrongNetwork ? 'disabled-opacity' : ''
          }`"
          boundaryClass="keeperMintFxToken-boundary"
          @change="onSelectToken"
          :value="token"
          :hideLabel="true"
          :disabled="wrongNetwork"
        />
        <button
          class="uk-button uk-button-primary uk-width-1-1 hfi-button hfi-fxtoken-button uk-margin-small-top uk-margin-small-bottom"
          type="button"
          @click="onStake"
          :disabled="!canStake"
        >
          deposit
          {{ token }}
          <img
            style="margin-top: -4px"
            uk-svg
            :src="`${token}Logo.png`"
            :alt="`handle.fi ${token} logo`"
            width="24"
          />
        </button>

        <button
          class="uk-button uk-button-primary uk-width-1-1 hfi-button hfi-fxtoken-button uk-margin-small-top"
          type="button"
          @click="onUnstake"
          :disabled="!this.canUnstake"
        >
          withdraw
          {{ token }}
          <img
            style="margin-top: -4px"
            uk-svg
            :src="`${token}Logo.png`"
            :alt="`handle.fi ${token} logo`"
            width="24"
          />
        </button>

        <button
          class="uk-button uk-button-primary uk-width-1-1 hfi-button hfi-fxtoken-button uk-margin-small-top uk-margin-xsmall-bottom uk-hidden"
          type="button"
        >
          claim rewards
        </button>

        <!--
          <button
            class="uk-button uk-button-primary uk-width-expand hfi-button uk-margin-small-top uk-margin-small-bottom"
            type="button"
          >
            rewards
          </button>
          -->
      </div>
    </div>

    <div
      id="protocol-keeper-stake-modal"
      v-if="account && sdk"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <FxKeeperStake
          :token="token"
          :balance="balance"
          @stake-success="onStakeSuccess"
        />
      </div>
    </div>

    <div
      id="protocol-keeper-unstake-modal"
      v-if="account && sdk && staked"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-small uk-width-auto uk-padding-xsmall-top"
      >
        <a class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>
        <FxKeeperUnstake
          :token="token"
          :staked="staked"
          @unstake-success="onStakeSuccess"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import Token from "@/types/Token";
import SelectFxToken from "@/components/SelectFxToken";
import { SUPPORTED_FX_TOKENS } from "@/utils/constants/supportedTokens";
import Network from "@/types/Network";
import { getPrice } from "@/utils/prices";
import { toDollarsAndCents } from "@/utils/currency";
import Loading from "@/components/Loading";
import FxKeeperStake from "@/components/FxKeeperStake";
import FxKeeperUnstake from "@/components/FxKeeperUnstake";
import UIkit from "uikit";
import { switchNetwork } from "@/utils/wallet";
import { getTokenPrice } from "@/utils/coingecko";
import config from "@/contracts.config.json";

const DAY_SECONDS = 24 * 60 * 60;
const YEAR_SECONDS = DAY_SECONDS * 365;

export default {
  name: "KeeperPools",
  components: {
    SelectFxToken,
    Loading,
    FxKeeperStake,
    FxKeeperUnstake,
  },
  data() {
    return {
      token: store.state.token || Token.fxAUD,
      stakedByFxToken: {},
      totalStakedByFxToken: {},
      usdValueByFxToken: {},
      hasRewardsByFxToken: {},
      Network,
      switchNetwork,
      totalForexDistributionRate: undefined,
      forexPrice: 0,
      pools: undefined,
      poolRewardRatios: {},
    };
  },
  watch: {
    supportedTokens() {
      this.fetchBalances();
    },
    keeperPool() {
      this.setStakedBalance();
      this.setTotalStaked();
      this.setRewards();
      this.setPoolRewardRatio();
    },
    async network() {
      this.stakedByFxToken = {};
      if (!this.sdk) return;
      await this.initialise();
    },
    async account() {
      this.stakedByFxToken = {};
      if (!this.sdk) return;
      await this.initialise();
    },
    token() {
      this.setFxToUSD();
    },
    async sdk() {
      if (!this.sdk) return;
      await this.initialise();
    },
  },
  computed: {
    sdk() {
      return store.state.refHandleSDK.get();
    },
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    isArbitrum() {
      return this.network === Network.arbitrum;
    },
    canTransact() {
      return this.account && this.isArbitrum && this.sdk;
    },
    canStake() {
      return this.canTransact && this.balance && this.balance.gt(0);
    },
    canUnstake() {
      return this.canTransact && this.staked && this.staked.gt(0);
    },
    supportedTokens() {
      return this.sdk
        ? this.sdk.protocol.fxTokens.filter((t) =>
            SUPPORTED_FX_TOKENS.includes(t.symbol)
          )
        : [];
    },
    keeperPool() {
      if (!this.sdk) {
        return undefined;
      }
      return this.sdk.keeperPools[this.token];
    },
    staked() {
      return this.stakedByFxToken[this.token];
    },
    loadingStaked() {
      return !this.staked && this.isArbitrum;
    },
    displayStaked() {
      return `${
        this.staked && this.isArbitrum
          ? parseFloat(
              toDollarsAndCents(ethers.utils.formatEther(this.staked))
            ).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })
          : "0.00"
      } ${this.token}`;
    },
    displayTotalStaked() {
      const totalStaked = this.totalStakedByFxToken[this.token];

      if (!totalStaked) {
        return "";
      }

      return `${toDollarsAndCents(
        ethers.utils.formatEther(totalStaked)
      ).toFixed(2)} ${this.token}`;
    },
    balance() {
      return store.state.balances[this.token];
    },
    loadingBalance() {
      return !this.balance && this.isArbitrum;
    },
    displayBalance() {
      return `${
        this.balance && this.isArbitrum
          ? toDollarsAndCents(ethers.utils.formatEther(this.balance)).toFixed(2)
          : "0.00"
      } ${this.token}`;
    },
    apy() {
      const poolRewardRatio = this.poolRewardRatios[this.token];

      if (
        !this.totalForexDistributionRate ||
        !poolRewardRatio ||
        !this.forexPrice ||
        !this.tvl
      ) {
        return;
      }

      const rewardRate = this.totalForexDistributionRate
        .mul(poolRewardRatio)
        .div(ethers.constants.WeiPerEther);

      const rewardsOverAYearBN = rewardRate.mul(YEAR_SECONDS);

      const rewardsOverAYear = toDollarsAndCents(
        ethers.utils.formatEther(rewardsOverAYearBN)
      );

      const valueOfRewardsOverAYear = rewardsOverAYear * this.forexPrice * 100;

      const apy = valueOfRewardsOverAYear / this.tvl;
      return `${apy.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;
    },
    tvl() {
      const totalStakedBN = this.totalStakedByFxToken[this.token];
      const usdValueOfToken = this.usdValueByFxToken[this.token];

      if (!totalStakedBN || !usdValueOfToken) {
        return;
      }

      const totalStaked = toDollarsAndCents(
        ethers.utils.formatEther(totalStakedBN)
      );

      return totalStaked * usdValueOfToken;
    },
    wrongNetwork() {
      return this.network !== Network.arbitrum;
    },
  },

  async mounted() {
    if (!this.sdk) return;
    await this.initialise();
  },

  methods: {
    async initialise() {
      await this.setProtocolRewardData();
      this.fetchBalances();
      this.setStakedBalance();
      this.setTotalStaked();
      this.setRewards();
      this.setForexPrice();
      this.setFxToUSD();
      this.setPoolRewardRatio();
    },
    onStakeSuccess() {
      this.fetchBalances();
      this.setStakedBalance();
      this.setTotalStaked();
    },
    onSelectToken(token) {
      this.token = token;
      store.commit("setToken", { token: this.token });
    },
    onStake() {
      UIkit.modal("#protocol-keeper-stake-modal").show();
    },
    onUnstake() {
      UIkit.modal("#protocol-keeper-unstake-modal").show();
    },
    fetchBalances() {
      if (!this.isArbitrum) return;
      store.dispatch("updateBalances", this.supportedTokens);
    },
    async setStakedBalance() {
      if (!this.account || !this.isArbitrum || !this.keeperPool) return;

      const staked = await this.keeperPool.balanceOfStake(this.account);

      this.stakedByFxToken = {
        ...this.stakedByFxToken,
        [this.token]: staked,
      };
    },
    async setTotalStaked() {
      if (!this.keeperPool) return;

      const totalStaked = await this.keeperPool.getPoolTotalDeposit();

      this.totalStakedByFxToken = {
        ...this.totalStakedByFxToken,
        [this.token]: totalStaked,
      };
    },
    async setFxToUSD() {
      if (this.usdValueByFxToken[this.token]) {
        return;
      }

      const usdPrice = await getPrice(this.token.substring(2), "USD");

      this.usdValueByFxToken = {
        ...this.usdValueByFxToken,
        [this.token]: usdPrice,
      };
    },
    async setProtocolRewardData() {
      const [pools, forexDistributionRate] = await Promise.all([
        this.sdk.contracts.rewardPool.getPoolsData(),
        this.sdk.contracts.rewardPool.forexDistributionRate(),
      ]);

      this.totalForexDistributionRate = forexDistributionRate;
      this.pools = pools;
    },
    async setPoolRewardRatio() {
      if (this.poolRewardRatios[this.token]) {
        return;
      }

      const token = this.supportedTokens.find((t) => t.symbol === this.token);

      const poolAlias = await this.sdk.contracts.rewardPool.getFxTokenPoolAlias(
        token.address,
        2
      );

      const { poolId } = await this.sdk.contracts.rewardPool.getPoolIdByAlias(
        poolAlias
      );

      const ratio = this.pools.poolRatios[poolId.toNumber()];

      this.poolRewardRatios = {
        ...this.poolRewardRatios,
        [this.token]: ratio,
      };
    },
    async setRewards() {
      if (!this.keeperPool) {
        return;
      }

      const result = await this.keeperPool.balanceOfRewards(this.account);

      const hasRewards = result.collateralAmounts.some((balance) =>
        balance.gt(0)
      );

      this.hasRewardsByFxToken = {
        ...this.hasRewardsByFxToken,
        [this.token]: hasRewards,
      };
    },
    async setForexPrice() {
      this.forexPrice = await getTokenPrice(
        config.arbitrum.forex,
        Network.arbitrum
      );
    },
  },
};
</script>
