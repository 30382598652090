<template>
  <div
    :class="`uk-card uk-card-small hfi-card-padding-custom hfi-border-remove uk-flex uk-height-1-1 ${
      loading
        ? 'uk-text-center uk-flex-center uk-flex-middle'
        : 'uk-flex-bottom'
    }`"
  >
    <div v-if="loading">
      <Loading scale=".5" />
    </div>
    <div v-else>
      <div
        :class="`uk-margin-bottom ${wrongNetwork ? 'disabled-opacity' : ''}`"
      >
        <div>
          <span class="hfi-text-highlight">est. APY: {{ apy }}%</span>
        </div>
        <div>total staked: {{ displayTotalStakingTokenStaked }}</div>
      </div>
      <div class="uk-margin-top uk-margin-bottom">
        <div :class="disableClass">
          bal: {{ displayUserStakingTokenBalance }}
        </div>
        <div :class="disableClass">your staked: {{ displayStaked }}</div>
        <div :class="disableClass">
          unclaimed rewards: {{ displayUnclaimedRewards }}
        </div>
      </div>
      <div class="uk-flex uk-margin-small-top uk-button-group">
        <button
          id="deposit-button"
          class="uk-button uk-button-primary hfi-button hfi-forex-button"
          type="button"
          :disabled="!canDeposit"
          @click.prevent="() => openStakeModal(`#lp-stake-modal-${modalId}`)"
        >
          <span>stake</span>
        </button>
        <button
          id="withdraw-button"
          class="uk-button uk-button-primary hfi-button hfi-forex-button"
          type="button"
          :disabled="!canWithdraw"
          @click.prevent="() => openStakeModal(`#lp-withdraw-modal-${modalId}`)"
        >
          <span>unstake</span>
        </button>
        <button
          id="claim-rewards"
          class="uk-button uk-button-primary hfi-button hfi-forex-button"
          type="button"
          :disabled="!canClaim"
          @click="claim"
        >
          <span>claim rewards</span>
        </button>
      </div>
      <StakingModal
        :id="`lp-stake-modal-${modalId}`"
        title="stake"
        buttonText="stake"
        :tokenSymbol="stakingTokenName"
        :balance="userStakingTokenBalance"
        :onSubmit="deposit"
      />
      <StakingModal
        :id="`lp-withdraw-modal-${modalId}`"
        title="unstake"
        buttonText="unstake"
        :tokenSymbol="stakingTokenName"
        :balance="staked"
        :onSubmit="withdraw"
      />
    </div>
  </div>
</template>

<script>
import handleLPStakingABI from "@/abi/handleLPStaking";
import erc20Abi from "@/abi/erc20";
import { signer, arbitrumProvider } from "@/utils/wallet";
import { ethers } from "ethers";
import { store } from "@/store";
import { fetchForexBalance } from "@/utils/forex";
import StakingModal from "@/components/pools/StakingModal";
import Loading from "@/components/Loading";
import UIkit from "uikit";
import { toDollarsAndCents } from "@/utils/currency";
import Network from "@/types/Network";
import { getTokenPrice } from "@/utils/coingecko";
import { switchNetwork } from "@/utils/wallet";

export default {
  name: "LPStakingPool",
  components: {
    StakingModal,
    Loading,
  },
  props: [
    "logo",
    "contractAddress", // address of the contract handle deployed
    "stakingTokenName",
    "stakingTokenAddress",
    "getPoolTVL",
  ],
  data() {
    return {
      //general
      modalId: this.contractAddress,
      stakingContract: undefined,
      totalStakingTokenStaked: undefined,
      rewardTokenPrice: undefined,
      rewardRate: undefined,
      // staking token contract
      stakingTokenContract: undefined,
      stakingTokenSymbol: undefined,
      stakingTokenDecimals: undefined,
      usdValueLockedInLP: undefined,
      stakingTokenTotalSupply: undefined,
      // reward token contract
      rewardsTokenContract: undefined,
      rewardsTokenSymbol: undefined,
      rewardsTokenDecimals: undefined,
      // user balances
      staked: undefined,
      unclaimedRewards: undefined,
    };
  },
  mounted() {
    this.initialise();
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    wrongNetwork() {
      return this.network !== Network.arbitrum;
    },
    canInteract() {
      return !this.wrongNetwork && this.account;
    },
    loading() {
      return (
        !this.rewardsTokenSymbol ||
        !this.stakingTokenDecimals ||
        !this.rewardsTokenDecimals ||
        !this.totalStakingTokenStaked
      );
    },
    signer() {
      return this.canInteract
        ? signer
        : new ethers.VoidSigner(
            "0x8ba1f109551bD432803012645Ac136ddd64DBA72", // random address
            arbitrumProvider
          );
    },
    userStakingTokenBalance() {
      return store.state.balances[this.stakingTokenName];
    },
    displayUserStakingTokenBalance() {
      return this.displayBalanceWithDefault(
        this.userStakingTokenBalance,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    displayStaked() {
      return this.displayBalanceWithDefault(
        this.staked,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    displayUnclaimedRewards() {
      return this.displayBalanceWithDefault(
        this.unclaimedRewards,
        this.rewardsTokenDecimals,
        this.rewardsTokenSymbol
      );
    },
    canDeposit() {
      return (
        this.canInteract &&
        this.userStakingTokenBalance &&
        this.userStakingTokenBalance.gt(0)
      );
    },
    canWithdraw() {
      return this.canInteract && this.staked && this.staked.gt(0);
    },
    canClaim() {
      return (
        this.canInteract && this.unclaimedRewards && this.unclaimedRewards.gt(0)
      );
    },
    valueOfStakingToken() {
      if (!this.stakingTokenTotalSupply || !this.usdValueLockedInLP) {
        return undefined;
      }

      return (
        this.usdValueLockedInLP /
        toDollarsAndCents(
          ethers.utils.formatUnits(this.stakingTokenTotalSupply, 18)
        )
      );
    },
    displayTotalStakingTokenStaked() {
      return this.displayBalanceWithDefault(
        this.totalStakingTokenStaked,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    valueOfTotalStakingTokenStaked() {
      if (!this.valueOfStakingToken || !this.totalStakingTokenStaked)
        return undefined;

      return (
        this.valueOfStakingToken *
        toDollarsAndCents(
          ethers.utils.formatUnits(this.totalStakingTokenStaked, 18)
        )
      );
    },
    valueOfRewardsIssuedOverAYear() {
      if (!this.rewardRate || !this.rewardTokenPrice) {
        return undefined;
      }

      const rewardIssuedInAYear = toDollarsAndCents(
        ethers.utils.formatUnits(this.rewardRate.mul(60 * 60 * 24 * 365), 18)
      );

      return rewardIssuedInAYear * this.rewardTokenPrice;
    },
    apy() {
      if (
        !this.valueOfRewardsIssuedOverAYear ||
        !this.valueOfTotalStakingTokenStaked
      ) {
        return undefined;
      }

      return (
        (this.valueOfRewardsIssuedOverAYear /
          this.valueOfTotalStakingTokenStaked) *
        100
      ).toFixed(2);
    },
    disableClass() {
      return !this.wrongNetwork ? "" : "disabled-opacity";
    },
  },
  watch: {
    account() {
      this.initialise();
    },
    network() {
      this.initialise();
    },
    async stakingTokenContract() {
      if (!this.stakingTokenContract) return;

      this.updateTokenDecimals(
        this.stakingTokenContract,
        "stakingTokenDecimals"
      );
      this.updateStakingTokenTotalSupply();
      this.updateRewardRate();

      this.updateTotalStaked();

      if (this.canInteract) {
        this.getUsersLpTokenBalance();
      }
    },
    async rewardsTokenContract() {
      if (!this.rewardsTokenContract) return;

      this.updateRewardTokenPrice();
      this.updateTokenSymbol(this.rewardsTokenContract, "rewardsTokenSymbol");
      this.updateTokenDecimals(
        this.rewardsTokenContract,
        "rewardsTokenDecimals"
      );
    },
    async stakingContract() {
      if (!this.stakingContract) return;
      this.updateValueInPool();

      if (this.canInteract) {
        this.updateUsersBalance(this.stakingContract, "staked");
        this.updateUnclaimedRewards();
      }
    },
  },
  methods: {
    async initialise() {
      this.staked = undefined;
      this.unclaimedRewards = undefined;

      this.stakingContract = new ethers.Contract(
        this.contractAddress,
        handleLPStakingABI,
        this.signer
      );

      this.stakingContract.stakingToken().then((address) => {
        this.stakingTokenContract = new ethers.Contract(
          address,
          erc20Abi,
          this.signer
        );
      });

      this.stakingContract.rewardsToken().then((address) => {
        this.rewardsTokenContract = new ethers.Contract(
          address,
          erc20Abi,
          this.signer
        );
      });
    },
    async deposit(amount) {
      const allowance = await this.stakingTokenContract.allowance(
        this.account,
        this.contractAddress
      );

      if (allowance.lt(amount)) {
        await this.stakingTokenContract.approve(
          this.contractAddress,
          ethers.constants.MaxUint256
        );
      }

      const tx = await this.stakingContract.stake(amount);
      await tx.wait(1);

      this.getUsersLpTokenBalance();
      this.updateUsersBalance(this.stakingContract, "staked");
      this.updateTotalStaked();
    },
    async withdraw(amount) {
      const tx = await this.stakingContract.withdraw(amount);
      await tx.wait(1);

      this.getUsersLpTokenBalance();
      this.updateUsersBalance(this.stakingContract, "staked");
      this.updateTotalStaked();
    },
    async claim() {
      const tx = await this.stakingContract.getReward();
      await tx.wait(1);

      await Promise.all([this.updateUnclaimedRewards(), fetchForexBalance()]);
    },
    async updateTokenSymbol(contract, propertyToUpdate) {
      this[propertyToUpdate] = await contract.symbol();
    },
    async updateUsersBalance(contract, propertyToUpdate) {
      this[propertyToUpdate] = await contract.balanceOf(this.account);
    },
    async updateTokenDecimals(contract, propertyToUpdate) {
      this[propertyToUpdate] = await contract.decimals();
    },
    displayBalance(balance, decimals, symbol) {
      return `${parseFloat(
        toDollarsAndCents(ethers.utils.formatUnits(balance, decimals))
      ).toLocaleString(undefined, this.digits(0, 2))} ${symbol}`;
    },
    displayBalanceWithDefault(balance, decimals, symbol) {
      return balance
        ? this.displayBalance(balance, decimals, symbol)
        : `0 ${symbol}`;
    },
    async updateUnclaimedRewards() {
      this.unclaimedRewards = await this.stakingContract.earned(this.account);
    },
    openStakeModal(id) {
      UIkit.modal(id).show();
    },
    async updateValueInPool() {
      this.usdValueLockedInLP = await this.getPoolTVL();
    },
    async updateStakingTokenTotalSupply() {
      this.stakingTokenTotalSupply = await this.stakingTokenContract.totalSupply();
    },
    async updateRewardTokenPrice() {
      this.rewardTokenPrice = await getTokenPrice(
        "0xdb298285fe4c5410b05390ca80e8fbe9de1f259b", // dont hardcode this
        Network.arbitrum
      );
    },
    async updateRewardRate() {
      this.rewardRate = await this.stakingContract.rewardRate();
    },
    switchNetwork() {
      switchNetwork(Network.arbitrum);
    },
    updateTotalStaked() {
      this.stakingTokenContract
        .balanceOf(this.contractAddress)
        .then((balance) => {
          this.totalStakingTokenStaked = balance;
        });
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
    getUsersLpTokenBalance() {
      store.dispatch("updateBalances", [
        {
          symbol: this.stakingTokenName,
          address: this.stakingTokenAddress,
        },
      ]);
    },
  },
};
</script>
