<template>
  <div class="uk-card uk-card-small hfi-card-padding-custom hfi-border-remove">
    <div v-if="loading">
      <Loading scale=".5" />
    </div>
    <div v-else>
      <div
        class="uk-flex uk-flex-wrap uk-grid-small uk-grid-row-collapse uk-grid-match"
        uk-grid
      >
        <div class="uk-width-2-3">
          <div class="uk-margin-small-bottom">
            <!-- <div :class="disableClass">est. max APY: {{ displayPoolAPY }}</div> -->
            <span :class="`hfi-text-highlight ${disableClass}`">
              est. APY: {{ displayAPY }}
              <a
                v-if="!disableClass"
                class="hfi-info-button uk-margin-remove-top"
                uk-icon="icon: question; ratio: .75"
                :uk-tooltip="'title: ' + apyTooltipText + '; pos: right'"
              />
            </span>
            <div v-if="canRefund" :class="`hfi-warning ${disableClass}`">
              <sup>*</sup> upgrade to governance v2
            </div>
            <div :class="disableClass">
              total staked v1 (deprecated): {{ displayRetiredTotalLocked }}
            </div>
            <div :class="disableClass">
              total staked v2: {{ displayTotalLocked }}
            </div>
          </div>
          <div class="uk-margin-small-top">
            <div :class="disableClass">
              <div :class="disableClass">
                your locked: {{ displayLockedBalance }}
              </div>
            </div>
            <div :class="disableClass">bal: {{ displayVeForexBalance }}</div>
            <div
              :class="`disableClass ${
                displayCurrentLockDuration === 'lock expired'
                  ? 'hfi-warning'
                  : ''
              }`"
            >
              lock duration: {{ displayCurrentLockDuration }}
            </div>
            <div :class="disableClass">
              unclaimed rewards: {{ displayUnclaimedRewards }}
            </div>
            <div :class="`hfi-warning ${disableClass}`" v-if="canRefund">
              your locked v1 bal: {{ displayUnclaimedRefund }} <sup>*</sup>
            </div>
          </div>
        </div>
        <div class="uk-width-1-3">
          <div class="uk-flex uk-flex-column uk-flex-center">
            <button
              v-if="!initialised || !canRefund"
              id="deposit-button"
              class="uk-button uk-button-primary hfi-button hfi-forex-button"
              type="button"
              :disabled="!canDeposit"
              @click.prevent="() => openStakeModal(`#${modalId}`)"
            >
              <span>{{ isExtendingLockEndDate ? "extend lock" : "lock" }}</span>
            </button>
            <button
              id="refund-rewards"
              style="color: #ffb45a"
              class="uk-button hfi-warning-button hfi-forex-button"
              type="button"
              @click="refund"
              v-if="initialised && canRefund"
            >
              <span>exit v1</span>
            </button>
            <button
              id="withdraw-button"
              class="uk-button uk-button-primary hfi-button hfi-forex-button uk-margin-top"
              type="button"
              :disabled="!canWithdraw"
              @click="withdraw"
            >
              <span>withdraw</span>
            </button>
            <button
              id="claim-rewards"
              class="uk-button uk-button-primary hfi-button hfi-forex-button uk-margin-top"
              type="button"
              :disabled="!canClaim"
              @click="claim"
            >
              <span>claim rewards</span>
            </button>
          </div>
        </div>
      </div>

      <StakingModal
        :id="modalId"
        title="lock"
        :buttonText="isExtendingLockEndDate ? 'extend lock' : 'lock'"
        :tokenSymbol="stakingTokenName"
        :balance="forexBalance"
        :onSubmit="lock"
        :disabled="isExtendingLockEndDate"
        :force-allow-submit="isExtendingLockEndDate"
        @change="onLockChangeAmount"
      >
        <div class="uk-flex uk-width-1-1@l">
          <br />
          <div class="uk-flex-1">
            <span v-if="contractData.lockedBalance.eq(0)">initial</span>
            <span v-if="contractData.lockedBalance.gt(0)">extend</span>
            &nbsp;
            <span>lock period</span>
          </div>
          <div class="uk-flex-1 uk-flex">
            <div
              class="uk-flex-1 uk-text-bold"
              style="cursor: pointer"
              @click="() => shiftLockPeriod(-1)"
            >
              -
            </div>
            <div class="uk-flex-1">{{ displayNewLockDuration }}</div>
            <div
              class="uk-flex-1 uk-text-bold"
              style="cursor: pointer"
              @click="() => shiftLockPeriod(+1)"
            >
              +
            </div>
          </div>
        </div>
      </StakingModal>
    </div>
  </div>
</template>

<script>
import { signer, arbitrumProvider } from "@/utils/wallet";
import { ethers } from "ethers";
import { store } from "@/store";
import StakingModal from "@/components/pools/StakingModal";
import Loading from "@/components/Loading";
import { toDollarsAndCents } from "@/utils/currency";
import Network from "@/types/Network";
import UIkit from "uikit";
import erc20Abi from "@/abi/erc20";
import config from "@/contracts.config.json";

const LOCK_PERIOD_WEEKS = [
  0, // increase locked amount, dont extend lock (only if already locked)
  1, // 1 week
  4, // ~1 month
  24, // ~6 months
  52, // ~1 year
  98, // ~2 years
  146, // ~3 years
  196, // ~4 years
];

const DAY_SECONDS = 24 * 60 * 60;
const YEAR_SECONDS = DAY_SECONDS * 365;
const WEEK_SECONDS = DAY_SECONDS * 7;
const MAX_LOCK_SECONDS = 4 * 365 * 24 * 60 * 60;

export default {
  name: "GovernancePool",
  components: {
    StakingModal,
    Loading,
  },
  data() {
    return {
      initialised: false,
      //general
      modalId: `${this.$route.name.toLowerCase()}-governance-modal`,
      stakingTokenName: "FOREX",
      lockPeriodIndex: 1,
      apyTooltipText:
        "annual percentage yield.<br/>assumes 1 veFOREX = 1 FOREX<br/>(1 FOREX locked 4 years)",
      // staking token contract
      stakingTokenDecimals: undefined,
      retiredContract: undefined,
      contractData: {
        totalForexLocked: ethers.constants.Zero,
        gForexBalance: ethers.constants.Zero,
        lockedBalance: ethers.constants.Zero,
        lockEndDate: 0,
        rewardsClaimable: ethers.constants.Zero,
        governanceRewardPoolRatio: ethers.constants.Zero,
        governanceRewardPoolTotalDeposits: ethers.constants.Zero,
        totalForexDistributionRate: ethers.constants.Zero,
        retiredLockedBalance: ethers.constants.Zero,
        retiredTotalForexLocked: ethers.constants.Zero,
      },
    };
  },
  mounted() {
    this.initialise();
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    isExtendingLockEndDate() {
      return this.contractData.lockedBalance.gt(0) && this.lockPeriodIndex > 0;
    },
    wrongNetwork() {
      return this.network !== Network.arbitrum;
    },
    canInteract() {
      return !this.wrongNetwork && this.account && this.sdk && this.initialised;
    },
    loading() {
      return false;
    },
    forexBalance() {
      return store.getters.forexBalance;
    },
    signer() {
      return this.canInteract
        ? signer
        : new ethers.VoidSigner(
            "0x8ba1f109551bD432803012645Ac136ddd64DBA72", // random address
            arbitrumProvider
          );
    },
    forexContract() {
      return new ethers.Contract(config.arbitrum.forex, erc20Abi, this.signer);
    },
    displayPoolAPY() {
      if (
        !this.contractData.totalForexLocked ||
        !this.contractData.totalForexDistributionRate ||
        this.contractData.totalForexLocked.isZero() ||
        this.contractData.totalForexDistributionRate.isZero()
      ) {
        return "-";
      }

      const governanceDistributionRate = this.contractData.totalForexDistributionRate
        .mul(this.contractData.governanceRewardPoolRatio)
        .div(ethers.constants.WeiPerEther);

      const rewardsOverAYearBN = governanceDistributionRate.mul(YEAR_SECONDS);

      const rewardsOverAYear = toDollarsAndCents(
        ethers.utils.formatEther(rewardsOverAYearBN)
      );

      const totalForexLocked = toDollarsAndCents(
        ethers.utils.formatEther(this.contractData.totalForexLocked)
      );

      const APY = (rewardsOverAYear / totalForexLocked) * 100;

      return `${APY.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;
    },
    displayAPY() {
      if (this.contractData.governanceRewardPoolTotalDeposits.eq(0))
        return "n/a";
      const governanceDistributionRate = this.contractData.totalForexDistributionRate
        .mul(this.contractData.governanceRewardPoolRatio)
        .div(ethers.constants.WeiPerEther);
      const logPrefix = "[veForex data]";
      const userShare = this.contractData.gForexBalance
        .mul(ethers.constants.WeiPerEther)
        .div(this.contractData.governanceRewardPoolTotalDeposits);
      const yearSeconds = 365 * 24 * 60 * 60;
      const extrapolatedRoi = governanceDistributionRate
        .mul(yearSeconds)
        .mul(ethers.constants.WeiPerEther)
        .div(this.contractData.governanceRewardPoolTotalDeposits);
      // Log info.
      console.log(
        `${logPrefix} governance pool ratio`,
        ethers.utils.formatEther(this.contractData.governanceRewardPoolRatio)
      );
      console.log(
        `${logPrefix} forex distribution rate`,
        ethers.utils.formatEther(this.contractData.totalForexDistributionRate)
      );
      console.log(
        `${logPrefix} gov distribution rate`,
        ethers.utils.formatEther(governanceDistributionRate)
      );
      console.log(
        `${logPrefix} total deposits`,
        ethers.utils.formatEther(
          this.contractData.governanceRewardPoolTotalDeposits
        )
      );
      console.log(
        `${logPrefix} user share`,
        ethers.utils.formatEther(userShare)
      );
      return `${(
        100 * parseFloat(ethers.utils.formatEther(extrapolatedRoi))
      ).toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;
    },
    displayVeForexBalance() {
      return this.displayBalanceWithDefault(
        this.contractData.gForexBalance,
        this.stakingTokenDecimals,
        `ve${this.stakingTokenName}`
      );
    },
    displayCurrentLockDuration() {
      const remainingWeeks =
        (this.contractData.lockEndDate - Date.now()) / (WEEK_SECONDS * 1000);
      return this.contractData.lockEndDate <= 0
        ? "n/a"
        : remainingWeeks <= 0
        ? "lock expired"
        : this.getDurationTextFromWeekNumber(remainingWeeks);
    },
    displayNewLockDuration() {
      const weekN = LOCK_PERIOD_WEEKS[this.lockPeriodIndex];
      return this.getDurationTextFromWeekNumber(weekN);
    },
    displayUnclaimedRewards() {
      return this.displayBalanceWithDefault(
        this.contractData.rewardsClaimable,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    displayUnclaimedRefund() {
      return this.displayBalanceWithDefault(
        this.contractData.retiredLockedBalance,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    canDeposit() {
      const canCreateOrExtendLock =
        this.forexBalance?.gt(0) || this.contractData?.gForexBalance.gt(0);
      return this.canInteract && canCreateOrExtendLock && !this.canWithdraw;
    },
    canWithdraw() {
      return (
        this.canInteract &&
        this.contractData?.lockedBalance.gt(0) &&
        Date.now() >= this.contractData?.lockEndDate
      );
    },
    canClaim() {
      return (
        this.canInteract &&
        this.contractData.rewardsClaimable &&
        this.contractData.rewardsClaimable.gt(0)
      );
    },
    canRefund() {
      return this.canInteract && this.contractData.retiredLockedBalance.gt(0);
    },
    displayLockedBalance() {
      return this.displayBalanceWithDefault(
        this.contractData.lockedBalance,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    displayTotalLocked() {
      return this.displayBalanceWithDefault(
        this.contractData.totalForexLocked,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    displayRetiredTotalLocked() {
      return this.displayBalanceWithDefault(
        this.contractData.retiredTotalForexLocked,
        this.stakingTokenDecimals,
        this.stakingTokenName
      );
    },
    disableClass() {
      return !this.wrongNetwork ? "" : "disabled-opacity";
    },
  },
  watch: {
    account() {
      this.initialise();
    },
    network() {
      this.initialise();
    },
    async sdk() {
      // Load data whenever the SDK initialises.
      if (!this.sdk) return;
      await this.initialise();
    },
  },
  methods: {
    async initialise() {
      this.initialised = false;
      if (!this.sdk) return;
      // Fetch governance reward pool ID.
      const {
        found,
        poolId,
      } = await this.sdk.contracts.rewardPool.getPoolIdByAlias(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes("governancelock"))
      );
      if (!found)
        throw new Error("Could not find governance reward pool from alias");
      this.retiredContract = this.sdk.contracts.governanceLock.attach(
        config.arbitrum.governanceLockRetired
      );
      const [
        gForexBalance,
        lock,
        rewardsClaimable,
        pool,
        pools,
        forexDistributionRate,
        totalForexLocked,
        lockRetired,
        retiredTotalForexLocked,
      ] = await Promise.all([
        this.sdk.contracts.governanceLock.balanceOf(this.account),
        this.sdk.contracts.governanceLock.locked(this.account),
        this.sdk.contracts.rewardPool.balanceOf(this.account),
        this.sdk.contracts.rewardPool.getPool(poolId),
        this.sdk.contracts.rewardPool.getPoolsData(),
        this.sdk.contracts.rewardPool.forexDistributionRate(),
        this.sdk.contracts.governanceLock.supply(),
        this.retiredContract.locked(this.account),
        this.retiredContract.supply(),
      ]);

      this.contractData.gForexBalance = gForexBalance;
      this.contractData.lockedBalance = lock.amount;
      this.contractData.lockEndDate = lock.end.toNumber() * 1000;
      // note: this is the rewards across all categories, not just veFOREX
      this.contractData.rewardsClaimable = rewardsClaimable;
      this.contractData.governanceRewardPoolRatio =
        pools.poolRatios[poolId.toNumber()];
      this.contractData.totalForexDistributionRate = forexDistributionRate;
      this.contractData.governanceRewardPoolTotalDeposits = pool.totalDeposits;
      this.contractData.totalForexLocked = totalForexLocked;
      this.contractData.retiredLockedBalance = lockRetired.amount;
      this.contractData.retiredTotalForexLocked = retiredTotalForexLocked;
      this.initialised = true;
    },
    async ensureAllowance(amount) {
      const allowance = await this.sdk.contracts.forex.allowance(
        this.account,
        this.sdk.contracts.governanceLock.address
      );
      if (allowance.gte(amount)) return;
      const tx = await this.sdk.contracts.forex.approve(
        this.sdk.contracts.governanceLock.address,
        ethers.constants.MaxUint256
      );
      await tx.wait(1);
    },
    async lock(amount) {
      await this.ensureAllowance(amount);
      let tx;
      const baseDate = Math.floor(
        (this.contractData.lockedBalance.gt(0)
          ? this.contractData.lockEndDate
          : Date.now()) / 1000
      );
      let unlockDate = ethers.BigNumber.from(
        Math.floor(
          baseDate + LOCK_PERIOD_WEEKS[this.lockPeriodIndex] * WEEK_SECONDS
        ).toString()
      );
      // Limit lock date to max of 4 years.
      if (unlockDate.toNumber() - baseDate > MAX_LOCK_SECONDS)
        unlockDate = baseDate + MAX_LOCK_SECONDS;
      if (this.contractData.lockedBalance.eq(0)) {
        // Create new lock
        tx = await this.sdk.contracts.governanceLock.createLock(
          amount,
          unlockDate
        );
      } else if (amount.gt(0)) {
        // Increase locked amount
        tx = await this.sdk.contracts.governanceLock.increaseAmount(amount);
      } else {
        // Increase unlock time
        console.log("increasing unlock time to ", new Date(unlockDate * 1000));
        tx = await this.sdk.contracts.governanceLock.increaseUnlockTime(
          unlockDate
        );
      }
      if (!tx) return;
      await tx.wait(1);
      await this.initialise();
    },
    async withdraw() {
      const tx = await this.sdk.contracts.governanceLock.withdraw();
      await tx.wait(1);
      await this.initialise();
    },
    async claim() {
      const tx = await this.sdk.contracts.rewardPool.claim();
      await tx.wait(1);
      await this.initialise();
    },
    async refund() {
      const tx = await this.retiredContract.withdraw();
      await tx.wait(1);
      await this.initialise();
    },
    displayBalance(balance, decimals, symbol) {
      return `${parseFloat(
        toDollarsAndCents(ethers.utils.formatUnits(balance, decimals), 4)
      ).toLocaleString(undefined, this.digits(0, 4))} ${symbol}`;
    },
    displayBalanceWithDefault(balance, decimals, symbol) {
      return balance
        ? this.displayBalance(balance, decimals, symbol)
        : `0 ${symbol}`;
    },
    openStakeModal(id) {
      UIkit.modal(id).show();
    },
    onLockChangeAmount(newAmount) {
      if (this.contractData.lockedBalance.eq(0) || newAmount.eq(0)) return;
      // Set the locked period to zero, as it is not possible to both
      // increase amount & extend lock at the same time.
      this.lockPeriodIndex = 0;
    },
    shiftLockPeriod(value) {
      // Increases or decreases index, ensuring it is always
      // within the range of the LOCK_PERIOD_WEEKS array.
      const newIndex = this.lockPeriodIndex + value;
      // Stop "0 weeks" from showing up if the user does not have
      // any amount currently locked.
      const minIndexAvailable = this.contractData.lockedBalance.gt(0) ? 0 : 1;
      this.lockPeriodIndex =
        newIndex >= LOCK_PERIOD_WEEKS.length
          ? minIndexAvailable
          : newIndex < minIndexAvailable
          ? LOCK_PERIOD_WEEKS.length - 1
          : newIndex;
    },
    getDurationTextFromWeekNumber(weekN) {
      const pluralText = (number, text) =>
        `${number.toFixed(0)} ${text}${parseInt(number) !== 1 ? "s" : ""}`;
      if (weekN < 1) return pluralText(weekN * 7, "day");
      if (weekN < 4) return pluralText(weekN, "week");
      const months = weekN / 4;
      if (months < 12) return pluralText(months, "month");
      const years = months / 12;
      return pluralText(years, "year");
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
