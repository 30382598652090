<template>
  <img
    :src="networkImageSrc"
    :alt="network"
    v-bind="$attrs"
    style="height: 25px !important"
  />
</template>

<script>
import Network from "@/types/Network";

export default {
  name: "NetworkIcon",
  props: {
    network: { type: String },
  },
  computed: {
    networkImageSrc() {
      const networkToSrcMap = {
        [Network.homestead]: "/ethereumLogo.svg",
        [Network.arbitrum]: "/arbitrumLogo.svg",
        [Network.polygon]: "/polygonLogo.svg",
      };

      return networkToSrcMap[this.network];
    },
  },
};
</script>
