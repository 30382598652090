export default [
  {
    name: "get_price_cumulative_last",
    outputs: [
      {
        type: "uint256[2]",
        name: ""
      }
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
    gas: "2344"
  },
  {
    name: "balanceOf",
    outputs: [
      {
        type: "uint256",
        name: ""
      }
    ],
    inputs: [
      {
        type: "address",
        name: "arg0"
      }
    ],
    stateMutability: "view",
    type: "function",
    gas: "2686"
  },
  {
    name: "allowance",
    outputs: [
      {
        type: "uint256",
        name: ""
      }
    ],
    inputs: [
      {
        type: "address",
        name: "arg0"
      },
      {
        type: "address",
        name: "arg1"
      }
    ],
    stateMutability: "view",
    type: "function",
    gas: "2931"
  },
  {
    name: "add_liquidity",
    outputs: [
      {
        type: "uint256",
        name: ""
      }
    ],
    inputs: [
      {
        type: "uint256[2]",
        name: "_deposit_amounts"
      },
      {
        type: "uint256",
        name: "_min_mint_amount"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    name: "remove_liquidity",
    outputs: [
      {
        type: "uint256[4]",
        name: ""
      }
    ],
    inputs: [
      {
        type: "address",
        name: "_pool"
      },
      {
        type: "uint256",
        name: "_burn_amount"
      },
      {
        type: "uint256[4]",
        name: "_min_amounts"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    name: "calc_token_amount",
    outputs: [
      {
        type: "uint256",
        name: ""
      }
    ],
    inputs: [
      {
        type: "uint256[2]",
        name: "_amounts"
      },
      {
        type: "bool",
        name: "_is_deposit"
      }
    ],
    stateMutability: "view",
    type: "function",
    gas: "2717"
  }
];
