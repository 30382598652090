import { ethers } from "ethers";
import { arbitrumProvider, homesteadProvider } from "@/utils/wallet";
import config from "@/contracts.config.json";
import erc20Abi from "@/abi/erc20";
import chainlinkAbi from "@/abi/chainlinkAggregator";
import { getTokenPrice } from "@/utils/coingecko";
import Network from "@/types/Network";
import { STAKING_POOLS } from "@/utils/constants/stakingPool";
import { toDollarsAndCents } from "@/utils/currency";

const arbVoidSigner = new ethers.VoidSigner(
  "0x8ba1f109551bD432803012645Ac136ddd64DBA72", // random address
  arbitrumProvider
);

const getTokenBalanceInPool = async (tokenAddress, poolAddress, signer) => {
  const token = new ethers.Contract(tokenAddress, erc20Abi, signer);

  const balancePromise = token.balanceOf(poolAddress);

  const decimalsPromise = token.decimals();

  return toDollarsAndCents(
    ethers.utils.formatUnits(await balancePromise, await decimalsPromise)
  );
};

const getUSDValueOfAssetsInLPPool = async (
  poolAddress,
  poolAssetOneAddress,
  poolAssetTwoAddress,
  signer
) => {
  const tokenOneBalancePromise = getTokenBalanceInPool(
    poolAssetOneAddress,
    poolAddress,
    signer
  );
  const tokenTwoBalancePromise = getTokenBalanceInPool(
    poolAssetTwoAddress,
    poolAddress,
    signer
  );

  const poolAssetOnePricePromise = getTokenPrice(
    poolAssetOneAddress,
    Network.arbitrum
  );

  const poolAssetTwoPricePromise = getTokenPrice(
    poolAssetTwoAddress,
    Network.arbitrum
  );

  const poolAssetOneBalance = await tokenOneBalancePromise;
  const poolAssetOnePrice = await poolAssetOnePricePromise;
  const poolAssetTwoBalance = await tokenTwoBalancePromise;
  const poolAssetTwoPrice = await poolAssetTwoPricePromise;

  return (
    poolAssetOneBalance * poolAssetOnePrice +
    poolAssetTwoBalance * poolAssetTwoPrice
  );
};

export const getTvlOfArbitrumSushiForexEthPool = () =>
  getUSDValueOfAssetsInLPPool(
    STAKING_POOLS.arbitrum.sushiWethForex.stakingToken,
    config.arbitrum.thirdPartyContracts.WETH,
    config.arbitrum.forex,
    arbVoidSigner
  );

export const getTvlOfArbitrumCurveEurFxEurPool = async () => {
  const tokenOneBalancePromise = getTokenBalanceInPool(
    config.arbitrum.fxTokens.fxEUR,
    STAKING_POOLS.arbitrum.curveEursFxEUR.stakingToken,
    arbVoidSigner
  );
  const tokenTwoBalancePromise = getTokenBalanceInPool(
    "0xd22a58f79e9481d1a88e00c343885a588b34b68b",
    STAKING_POOLS.arbitrum.curveEursFxEUR.stakingToken,
    arbVoidSigner
  );

  const chainlink = new ethers.Contract(
    "0xb49f677943bc038e9857d61e7d053caa2c1734c1",
    chainlinkAbi,
    homesteadProvider
  );

  const exchangeRateBNPromise = chainlink.latestAnswer();

  const poolAssetOneBalance = await tokenOneBalancePromise;
  const poolAssetTwoBalance = await tokenTwoBalancePromise;
  const exchangeRateBM = await exchangeRateBNPromise;

  const exchangeRate = ethers.utils.formatUnits(exchangeRateBM, 8);

  return (poolAssetOneBalance + poolAssetTwoBalance) * exchangeRate;
};
