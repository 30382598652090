<template>
  <div class="forex-liquidity-unstake">
    <h2 class="uk-h2 uk-margin-remove-bottom">FOREX liquidity unstake</h2>

    <form
      novalidate
      autocomplete="off"
      class="uk-form-width-large uk-margin-small-top"
    >
      <fieldset class="uk-fieldset uk-width-1-1">
        <div class="uk-margin-xsmall-top">
          <label
            class="uk-form-label uk-flex uk-width-expand uk-flex uk-flex-between"
            for="unStakeAmount"
          >
            <span> amount </span>

            <span v-if="stakeBalance">
              your staked:
              {{ formatEther(stakeBalance) }}
              {{ token }}
            </span>
          </label>
          <div class="uk-form-controls uk-position-relative uk-flex">
            <NumberInput
              class="uk-width-expand"
              id="unStakeAmount"
              type="number"
              placeholder="token to unstake"
              :alert="!this.hasEnoughUnstakeBalance"
              :value="unstakeAmount"
              :min="ethers.BigNumber.from(0)"
              :disabled="!canTransact"
              @change="onSetUnstakeAmount"
            />
            <button
              class="uk-button hfi-button hfi-input-button"
              @click.prevent="getMaxUnstakeAmount"
              :disabled="!canTransact"
            >
              max
            </button>
          </div>
        </div>

        <div class="uk-margin-small-top">
          <button
            id="unstakeButton"
            class="uk-button uk-button-primary uk-width-expand hfi-button"
            @click="unstake"
            type="button"
            :disabled="!canUnstake"
          >
            {{ unstakeButtonText }}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import Token from "@/types/Token";
import { store } from "@/store";
import NumberInput from "@/components/NumberInput";
import { addEventListener, removeEventListener } from "@/utils/event";
import Event from "@/types/Event";
import SelectFxToken from "@/components/SelectFxToken";
import { ethers } from "ethers";
import { showNotification } from "@/utils/utils";
import { fxKeeperPool } from "handle-sdk";
import { listenForConfirmations } from "@/contracts/utils/sendTransaction";

export default {
  name: "ForexLiquidityUnstake",
  components: {
    SelectFxToken,
    NumberInput,
  },
  data() {
    return {
      unstakeAmount: ethers.BigNumber.from(0),
      tokenBalance: ethers.BigNumber.from(0),
      stakeBalance: ethers.BigNumber.from(0),
      rewards: false,
      hasEnoughUnstakeBalance: true,
      token: store.state.token || Token.fxAUD,
      processingUnstakeTransaction: false,
      walletUpdateEventId: undefined,
      loadingData: true,
      loaded: false,
      Token,
      ethers,
      formatEther: (value, digits = 2) =>
        parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        ),
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    canTransact() {
      return (
        this.account &&
        this.sdk != null &&
        (!this.$route.meta?.networks ||
          this.$route.meta?.networks.includes(this.network)) &&
        !this.processingUnstakeTransaction
      );
    },
    canUnstake() {
      return (
        this.canTransact &&
        this.hasEnoughUnstakeBalance &&
        this.unstakeAmount.gt(0)
      );
    },
    unstakeLoadingButtonText() {
      if (!this.account) return "please connect wallet";
      if (
        this.$route.meta?.networks &&
        !this.$route.meta?.networks.includes(this.network)
      )
        return "please change network";
      if (this.loadingData || !this.sdk) return "loading...";
      if (this.processingUnstakeTransaction) return "processing...";
    },
    unstakeButtonText() {
      const loadingText = this.unstakeLoadingButtonText;
      if (loadingText) return loadingText;
      if (!this.hasEnoughUnstakeBalance) return "insufficient balance";
      return "unstake";
    },
    keeperPool() {
      if (this.sdk == null) return null;
      let keeperPool = this.sdk.keeperPools[this.token];
      if (keeperPool == null) {
        console.error(
          "The SDK returned a null class for the keeper pool. Constructing manually..."
        );
        keeperPool = new fxKeeperPool(
          this.sdk,
          this.token,
          this.sdk.contracts.fxKeeperPool
        );
      }
      return keeperPool;
    },
    network() {
      return store.state.network;
    },
  },
  mounted() {
    this.initialiseState();
  },
  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },
  watch: {
    async unstakeAmount() {
      this.hasEnoughUnstakeBalance = this.stakeBalance.gte(this.unstakeAmount);
    },
    async sdk() {
      this.keeperPool.gasLimit = ethers.BigNumber.from("5000000");
      this.stakeBalance = await this.keeperPool.balanceOfStake(this.account);
    },
  },
  methods: {
    clearForm: function () {
      this.stakeAmount = this.unstakeAmount = ethers.BigNumber.from(0);
    },
    initialiseState: async function () {
      this.processingUnstakeTransaction = true;
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );
      await this.resetState();
      this.processingUnstakeTransaction = false;
    },
    resetState: async function () {
      this.loadingData = true;
      this.clearForm();
      await this.loadData();
      this.loadingData = false;
    },
    loadData: async function () {
      this.loadingData = true;
      await this.setFxToken(this.token);
      this.loadingData = false;
    },
    async getRewardStatus() {
      try {
        const rewards = await this.keeperPool.balanceOfRewards(this.account);
        let isAllZero = true;
        for (let balance of rewards[1]) {
          if (balance.eq(0)) continue;
          isAllZero = false;
          break;
        }
        if (isAllZero) return false;
      } catch (error) {
        return false;
      }
    },
    onSetUnstakeAmount: async function (value) {
      if (value != null) this.unstakeAmount = value;
    },
    getMaxUnstakeAmount: async function () {
      await this.onSetUnstakeAmount(this.stakeBalance);
    },
    setFxToken: async function (option) {
      if (!this.account || !this.sdk) return;
      this.token = option;
      this.tokenBalance = await this.sdk.contracts[this.token].balanceOf(
        this.account
      );
      if (this.keeperPool != null) {
        this.stakeBalance = await this.keeperPool.balanceOfStake(this.account);
        this.rewards = await this.getRewardStatus();
      }
      this.clearForm();
    },
    unstake: async function () {
      this.processingUnstakeTransaction = true;
      let receipt;
      try {
        receipt = await this.keeperPool.unstake(this.unstakeAmount);
        await listenForConfirmations(
          receipt,
          "follow wallet instructions to confirm your unstake of " + this.token
        );
      } catch (error) {
        console.error(error);
        showNotification("error", "failed to stake");
      } finally {
        this.processingUnstakeTransaction = false;
        await this.resetState();
      }
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
<style>
.stakeButtons {
  display: flex;
}
</style>
