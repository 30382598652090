<template>
  <AddLPLiquidity
    :contractAddress="contractAddress"
    :tokenOneAddress="tokenOneAddress"
    :tokenTwoAddress="tokenTwoAddress"
    :calculateOtherTokenAmount="calculateOtherTokenAmount"
    :addLiquidity="addLiquidity"
    :lpTokenName="lpTokenName"
    :tokenApprovalAddress="tokenApprovalAddress"
  />
</template>

<script>
import NumberInput from "@/components/NumberInput";
import Loading from "@/components/Loading";
import AddLPLiquidity from "@/components/pools/AddLPLiquidity";
import { signer } from "@/utils/wallet";
import sushiFactoryPoolABI from "@/abi/sushiFactoryPool";
import sushiswapRouterAbi from "@/abi/sushiswapRouter";
import { ethers } from "ethers";
import { getDecimalsAmount } from "../../utils/utils";
import { store } from "@/store";

const SUSHI_SWAP_ROUTER_ADDRESS = "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"; // arbitrum

export default {
  name: "AddSushiLiquidity",
  components: {
    Loading,
    NumberInput,
    AddLPLiquidity,
  },
  props: [
    "lpTokenName",
    "contractAddress",
    "tokenOneAddress",
    "tokenTwoAddress",
  ],
  data() {
    return {
      tokenApprovalAddress: SUSHI_SWAP_ROUTER_ADDRESS,
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    lpContract() {
      return new ethers.Contract(
        this.contractAddress,
        sushiFactoryPoolABI,
        signer
      );
    },
    routerContract() {
      return new ethers.Contract(
        SUSHI_SWAP_ROUTER_ADDRESS,
        sushiswapRouterAbi,
        signer
      );
    },
  },
  methods: {
    async addLiquidity(amounts) {
      const [tokenOneAmount, tokenTwoAmount] = amounts;

      return this.routerContract.populateTransaction.addLiquidity(
        this.tokenOneAddress,
        this.tokenTwoAddress,
        tokenOneAmount,
        tokenTwoAmount,
        tokenOneAmount.div(1000).mul(995),
        tokenTwoAmount.div(1000).mul(995),
        this.account,
        Math.ceil(new Date().getTime() / 1000) + 60 * 30
      );
    },
    async calculateOtherTokenAmount(
      inputToken,
      inputTokenAmount,
      fromDecimals,
      toDecimals
    ) {
      const result = await this.lpContract.getReserves();

      const inputTokenAmountAdjusted = getDecimalsAmount(
        inputTokenAmount,
        fromDecimals,
        toDecimals
      );

      const price =
        inputToken === "One"
          ? result._reserve1
              .mul(ethers.constants.WeiPerEther)
              .div(result._reserve0)
          : result._reserve0
              .mul(ethers.constants.WeiPerEther)
              .div(result._reserve1);

      return inputTokenAmountAdjusted
        .mul(price)
        .div(ethers.constants.WeiPerEther);
    },
  },
};
</script>
